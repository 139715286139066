import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Tags = () => {
  const [activeTab, setActiveTab] = useState('People');
  const [tags, setTags] = useState([]);
  const [editTag, setEditTag] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const navigate = useNavigate();
  const fetchTags = async () => {
    const response = await axios.get(
      `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${activeTab}`
    );
    setTags(response.data);
  };

  useEffect(() => {
    fetchTags();
  }, [activeTab]);

  const handleEdit = (tag) => {
    setEditTag(tag);
  };

  const handleDelete = async (tag) => {
    try {
      const encodedCategory = encodeURIComponent(activeTab);
  
      // Construct the appropriate URL
      let requestUrl;
      if (activeTab === 'Route Transfer') {
        // Use the tagname directly for Route Transfer (it's the document ID)
        requestUrl = `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${encodedCategory}/${tag.id}`;
      } else if (activeTab === 'Simple Transfer') {
        // Use the tagname for Simple Transfer (it's a field in the transfer_tags document)
        requestUrl = `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${encodedCategory}/${tag.tagname}`;
      } else {
        console.error('Unsupported category for deletion:', activeTab);
        return;
      }
  
      // Perform the DELETE request
      const response = await axios.delete(requestUrl);
      console.log('Delete response:', response.data);
  
      // Refresh the list of tags
      fetchTags();
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };
  

  const handleSave = async (updatedTag) => {
    try {
      const encodedCategory = encodeURIComponent(activeTab);
  
      // Determine the correct URL for the category
      let requestUrl;
      if (activeTab === 'Route Transfer') {
        // Use the document ID for Route Transfer (editTag.id)
        requestUrl = `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${encodedCategory}/${editTag.id}`;
      } else if (activeTab === 'Simple Transfer') {
        // Use the tagname for Simple Transfer
        requestUrl = `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${encodedCategory}/${editTag.tagname}`;
      } else {
        console.error('Unsupported category:', activeTab);
        return;
      }
  
      // Send the PUT request
      console.log('Saving to URL:', requestUrl, 'with data:', updatedTag); // Debug
      await axios.put(requestUrl, updatedTag);
  
      // Clear the edit form and refresh the tags
      setEditTag(null);
      fetchTags();
    } catch (error) {
      console.error('Error saving tag:', error);
    }
  };
  

  const handleAdd = async (newTag) => {
    try {
      const encodedCategory = encodeURIComponent(activeTab);
  
      // Adjust the payload based on the category
      let payload;
      if (activeTab === 'Simple Transfer') {
        // Payload for Simple Transfer
        payload = {
          tagname:newTag.tagname,
          home: newTag.home,
          destination: newTag.destination,
        };
      } else if (activeTab === 'Route Transfer') {
        // Payload for Route Transfer
        payload = {
          tagname:newTag.tagname,
          name: newTag.name,
          current_idx: newTag.current_idx,
          stations: newTag.route.split(',').map((station) => station.trim()), // Convert route string to array
        };
      } else {
        console.error('Unsupported category:', activeTab);
        return;
      }
  
      // Make the POST request
      await axios.post(`https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${encodedCategory}`, payload);
      setShowAddForm(false);
      fetchTags(); // Refresh the tag list
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  };
  

  return (
    <div className="p-6">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)} // Go back to the previous page
        className="bg-gray-500 text-white px-4 py-2 rounded mb-4"
      >
        Back
      </button>
      <h1 className="text-2xl font-bold mb-4">Tag Management</h1>
      <div className="flex space-x-4">
        {['People', 'Simple Transfer', 'Route Transfer'].map((tab) => (
          <button
            key={tab}
            className={`py-2 px-4 rounded ${
              activeTab === tab ? 'bg-blue-500 text-white' : 'bg-gray-200'
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="mt-4">
      {activeTab != 'People' &&(
        <button
          className="bg-green-500 text-white px-4 py-2 rounded mb-4"
          onClick={() => setShowAddForm(true)}
        >
          Add New Tag
        </button>
      )}
        <table className="min-w-full border-collapse border border-gray-200">
          <thead>
            <tr>
              {Object.keys(tags[0] || {}).map((key) => (
                <th key={key} className="border border-gray-300 px-4 py-2">
                  {key}
                </th>
              ))}
              <th className="border border-gray-300 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tags.map((tag) => (
              <tr key={tag.id}>
                {Object.values(tag).map((value, idx) => (
                  <td key={idx} className="border border-gray-300 px-4 py-2">
                    {typeof value === 'object' && value !== null
                      ? JSON.stringify(value) // Convert objects to a JSON string
                      : value}
                  </td>
                ))}
                <td className="border border-gray-300 px-4 py-2">
                  <button
                    onClick={() => handleEdit(tag)}
                    className="bg-yellow-500 text-white px-2 py-1 rounded"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(tag)}
                    className="bg-red-500 text-white px-2 py-1 rounded ml-2"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {editTag && (
          <EditForm
            tag={editTag}
            category={activeTab}
            onSave={handleSave}
            onClose={() => setEditTag(null)}
          />
        )}
        {showAddForm && (
  <AddForm
    category={activeTab}
    onSave={handleAdd}
    onClose={() => setShowAddForm(false)}
  />
)}
      </div>
    </div>
  );
};


const EditForm = ({ tag, category, onSave, onClose }) => {
  const [formData, setFormData] = useState({
    ...tag,
    route: category === 'Route Transfer' && tag.stations
      ? tag.stations.join(', ') // Convert stations array to a string for editing
      : '', // Default route as an empty string for non-Route Transfer
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedTag =
      category === 'Route Transfer'
        ? {
            ...formData,
            stations: formData.route.split(',').map((station) => station.trim()), // Convert route string back to an array
          }
        : formData; // For Simple Transfer, no extra processing

    onSave(updatedTag); // Save the updated tag
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded shadow-lg space-y-4"
      >
        {/* Conditional Fields for Route Transfer */}
        {category === 'Route Transfer' && (
          <>
            <div>
              <label className="block text-sm font-bold mb-1">Job Name</label>
              <input
                type="text"
                value={formData.name || ''}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-1">Current Index</label>
              <input
                type="number"
                value={formData.current_idx || ''}
                onChange={(e) =>
                  setFormData({ ...formData, current_idx: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-1">Route</label>
              <input
                type="text"
                value={formData.route || ''}
                onChange={(e) =>
                  setFormData({ ...formData, route: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
                placeholder="Enter stations as comma-separated values"
              />
            </div>
          </>
        )}

        {/* Fields for Simple Transfer */}
        {category === 'Simple Transfer' && (
          <>
            <div>
              <label className="block text-sm font-bold mb-1">Home</label>
              <input
                type="text"
                value={formData.home || ''}
                onChange={(e) =>
                  setFormData({ ...formData, home: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-1">Destination</label>
              <input
                type="text"
                value={formData.destination || ''}
                onChange={(e) =>
                  setFormData({ ...formData, destination: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
              />
            </div>
          </>
        )}
       
        {/* Fields for People */}
        {category === 'People' && (
          <>
            <div>
              <label className="block text-sm font-bold mb-1">Home</label>
              <input
                type="text"
                value={formData.home || ''}
                onChange={(e) =>
                  setFormData({ ...formData, home: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-1">Name</label>
              <input
                type="text"
                value={formData.name || ''}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-1">Tag ID</label>
              <input
                type="text"
                value={formData.tagId || ''}
                onChange={(e) =>
                  setFormData({ ...formData, tagId: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
              />
            </div>
          </>
        )}

        {/* Buttons */}
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

  
  

  const AddForm = ({ onSave, onClose, category }) => {
    const [formData, setFormData] = useState(
      category === 'Route Transfer'
        ? { tagname: '', name: '', current_idx: '', route: '' } // Include 'tagname' for Route Transfer
        : { tagname: '', home: '', destination: '' } // Include 'tagname' for Simple Transfer
    );
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Perform validation
      if (!formData.tagname) {
        console.error('Tag name (ID) is required.');
        return;
      }
  
      if (category === 'Simple Transfer' && (!formData.home || !formData.destination)) {
        console.error('Home and Destination are required.');
        return;
      }
  
      if (
        category === 'Route Transfer' &&
        (!formData.name || !formData.current_idx || !formData.route)
      ) {
        console.error('Name, Current Index, and Route are required.');
        return;
      }
  
      onSave(formData); // Pass the new tag data to the save handler
    };
  
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
        <form
          onSubmit={handleSubmit}
          className="bg-white p-6 rounded shadow-lg space-y-4"
        >
          {/* Common Field for Tag Name/ID */}
          <div>
            <label className="block text-sm font-bold mb-1">Tag Name / ID</label>
            <input
              type="text"
              value={formData.tagname}
              onChange={(e) => setFormData({ ...formData, tagname: e.target.value })}
              className="w-full border px-3 py-2 rounded"
              placeholder="Enter tag name or ID"
            />
          </div>
  
          {/* Conditional Fields for Route Transfer */}
          {category === 'Route Transfer' && (
            <>
              <div>
                <label className="block text-sm font-bold mb-1">Job Name</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full border px-3 py-2 rounded"
                  placeholder="Enter job name"
                />
              </div>
              <div>
                <label className="block text-sm font-bold mb-1">Current Index</label>
                <input
                  type="number"
                  value={formData.current_idx}
                  onChange={(e) =>
                    setFormData({ ...formData, current_idx: e.target.value })
                  }
                  className="w-full border px-3 py-2 rounded"
                  placeholder="Enter current index"
                />
              </div>
              <div>
                <label className="block text-sm font-bold mb-1">Route</label>
                <input
                  type="text"
                  value={formData.route}
                  onChange={(e) =>
                    setFormData({ ...formData, route: e.target.value })
                  }
                  className="w-full border px-3 py-2 rounded"
                  placeholder="Enter stations as comma-separated values"
                />
              </div>
            </>
          )}
  
          {/* Fields for Simple Transfer */}
          {category === 'Simple Transfer' && (
            <>
              <div>
                <label className="block text-sm font-bold mb-1">Home</label>
                <input
                  type="text"
                  value={formData.home}
                  onChange={(e) => setFormData({ ...formData, home: e.target.value })}
                  className="w-full border px-3 py-2 rounded"
                  placeholder="Enter home"
                />
              </div>
              <div>
                <label className="block text-sm font-bold mb-1">Destination</label>
                <input
                  type="text"
                  value={formData.destination}
                  onChange={(e) =>
                    setFormData({ ...formData, destination: e.target.value })}
                  className="w-full border px-3 py-2 rounded"
                  placeholder="Enter destination"
                />
              </div>
            </>
          )}
  
          {/* Buttons */}
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
            
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              Add Tag
            </button>
          </div>
        </form>
      </div>
    );
  };
  
  

export default Tags;
