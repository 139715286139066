import React, { useEffect, useState } from "react";
import { useTheme } from "../Context/Context.js";
import Logo from "../../utils/Logo.png";
import { usePathDraw } from "../Context/Context.js";
import { useAIPathDraw } from "../Context/Context.js";
import { useRecordPath } from "../Context/Context.js";
import axios from "axios";
import { useZone } from "../Context/Context.js";
import convertToReal from "../helper_functions/convertToRealCoordinates.js";
import { useNavigate } from "react-router-dom";
import { addIntermediatePoints } from "../helper_functions/addIntermediatePoints.js";
function SideBar({ cameraZoom }) {
  const { theme, setTheme } = useTheme();
  const {
    pathRecord,
    setPathRecord,
    showStation,
    setShowStation,
    showpath,
    setShowpath,
    hoveredPath,
    setHoveredPath,
    canvasWidth,
    setCanvasWidth,
    cameraOffset,
    setCameraOffset,
    showParking,
    setShowParking,
    selectedParking,
    setSelectedParking,
    selectedBot,
    setSelectedBot,
    selectedPath,
    setSelectedPath,
    selectedZone = [],
    setSelectedZone,
    showCartParking,
    setShowCartParking,
    selectedCartParking,
    setSelectedCartParking,
    selectedStation,
    setSelectedStation,
    selectedPeople,
    setSelectedPeople,
    showBots,
    showPeople,
    setShowPeople,
    setShowBots,
    showZone,
    setShowZone,
    showMaterials,
    setShowMaterials,
    stationRecord,
    setStationRecord,
    stationMarkedCoordinate,
    setStationMarkedCoordinate,
    tracePath,
    setTracePath,
    tagName,
    settagName,
    botTraceArray,
    setBotTraceArray,
    jitterbotCoordinates,
    setjitterbotCoordinates,
    parkingSpaceCoordinates,
    setParkingSpaceCoordinates,
    startParkingSpaceRecord,
    setStartParkingSpaceRecord,
    startCartParkingSpaceRecord,
    setStartCartParkingSpaceRecord,
    cartParkingSpaceCoordinates,
    setCartParkingSpaceCoordinates,
    showParkingSpace,
    setShowParkingSpace,
    selectedParkingSpace,
    setSelectedParkingSpace,
  } = useRecordPath();
  const xFlipped = 1;
  const yFlipped = -1;
  const { ToRecord, setToRecord } = usePathDraw();
  const { AIpath, setAIpath, AIpathArray, setAIpathArray } = useAIPathDraw();
  const [toggle, setToggle] = useState(false);
  const [showList, setShowList] = useState(false);
  const [recordList, setRecordList] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [Bots, setBots] = useState(false);
  const [showIndividualBot, setshowIndividualBot] = useState(false);
  const [showIndividualPath, setshowIndividualPath] = useState(false);
  const [showIndividualStation, setshowIndividualStation] = useState(false);
  const [showIndividualPeople, setshowIndividualPeople] = useState(false);
  const [showIndividualZone, setShowIndividualZone] = useState(false);
  const [showIndividualCartParking, setShowIndividualCartParking] =
    useState(false);
  const [showIndividualParking, setShowIndividualParking] = useState(false);
  const [People, setPeople] = useState(false);
  const [Paths, setPaths] = useState(false);
  const [Materials, setMaterials] = useState(false);
  const [Zones, setZones] = useState(false);
  const [Forklifts, setForklifts] = useState(false);
  const [Stations, setStations] = useState(false);
  const [showStationRecord, setShowStationRecord] = useState(false);
  const { ZonePointArray, setZonePointArray, CreateZone, setCreateZone } =
    useZone();
  const img = new Image();
  img.src = "Drobot.jpg";
  const pathData = window.sessionStorage.getItem("pathData");
  const paths = pathData ? JSON.parse(pathData).paths : [];
  const zoneData = window.sessionStorage.getItem("zoneData");
  const zones = zoneData ? JSON.parse(zoneData).zones : [];
  const stationData = window.sessionStorage.getItem("stationData");
  const cartParkingData = stationData;
  const botMapping = JSON.parse(window.sessionStorage.getItem("botMapping"));
  const tags = JSON.parse(window.sessionStorage.getItem("tags"));
  const peopleMapping = JSON.parse(
    window.sessionStorage.getItem("PeopleMapping")
  );
  const userEmail = JSON.parse(window.sessionStorage.getItem("data")).email;
  const companyName = window.sessionStorage.getItem("companyName");
  const CompanyLogo = JSON.parse(
    window.sessionStorage.getItem("companyData")
  ).photo_url;
  const companymap = window.sessionStorage.getItem("companyName");
  const navigate = useNavigate();
  // console.log(companyName);
  //All the functions
  const handleSelectAll = () => {
    const newValue = !selectAll;
    setSelectAll(newValue);
    setBots(newValue);
    setPaths(newValue);
    setShowpath(newValue);
    setShowStation(newValue);
    setShowPeople(newValue);
    setForklifts(newValue);
    setShowMaterials(newValue);
    setShowZone(newValue);
    setStations(newValue);
    setShowBots(newValue);
    const allStations = stationData ? JSON.parse(stationData) : [];
    setSelectedStation(newValue ? allStations : []);
    setSelectedBot(newValue ? tags : []);
    setSelectedPath(newValue ? paths : []);
    setSelectedPeople(newValue ? tags : []);
    setSelectedZone(newValue ? zones : []);
    setShowCartParking(newValue);
    setShowParking(newValue);
    setSelectedParking(
      newValue
        ? JSON.parse(cartParkingData).filter((parking) => parking.bot_parking)
        : []
    );
    setSelectedCartParking(
      newValue
        ? JSON.parse(cartParkingData).filter(
            (parking) => parking.cart_parking_empty || parking.cart_parking_full
          )
        : []
    );
  };
  useEffect(() => {
    // console.log(botTraceArray);
  }, [botTraceArray]);

  const BotsHandler = () => {
    setShowBots(!showBots);
  };
  const PathsHandler = () => {
    setPaths(!Paths);
    setShowpath(!showpath);
  };
  const PeopleHandler = () => {
    setShowPeople(!showPeople);
  };
  const handleParkingSpaceRecord = async () => {
    if (!startParkingSpaceRecord) {
      const stationOptions = JSON.parse(
        window.sessionStorage.getItem("stationData")
      ).map((station) => station.stationName);
      const selectedStation = window.prompt(
        `Enter the station name:\n${stationOptions.join("\n")}`
      );
      if (!selectedStation || !stationOptions.includes(selectedStation)) {
        alert("Invalid station name!");
        return;
      }

      await setParkingSpaceCoordinates((prev) => ({
        ...prev,
        station: selectedStation,
      }));

      console.log(parkingSpaceCoordinates);
    }
    setStartParkingSpaceRecord(!startParkingSpaceRecord);
  };
  const handleCartParkingSpaceRecord = () => {
    if (!startCartParkingSpaceRecord) {
      const stationOptions = JSON.parse(
        window.sessionStorage.getItem("stationData")
      ).map((station) => station.stationName);
      const selectedStation = window.prompt(
        `Enter the station name:\n${stationOptions.join("\n")}`
      );
      const selectedstate = window.prompt("Enter state as full or empty");
      if (
        !selectedStation ||
        !stationOptions.includes(selectedStation) ||
        !selectedstate
      ) {
        alert("Invalid station name OR invalid state of parking space");
        return;
      }
      setCartParkingSpaceCoordinates((prev) => ({
        ...prev,
        station: selectedStation,
        state: selectedstate,
      }));
    }
    setStartCartParkingSpaceRecord(!startCartParkingSpaceRecord);
  };
  useEffect(() => {
    console.log(
      "Updated cartParkingSpaceCoordinates:",
      cartParkingSpaceCoordinates
    );
  }, [cartParkingSpaceCoordinates]);
  const ForkliftsHandler = () => {
    setForklifts(!Forklifts);
  };
  const MaterialsHandler = () => {
    setShowMaterials(!showMaterials);
  };
  const ZonesHandler = (e) => {
    setShowZone(e.target.checked);
  };
  const ZoneClickHandler = () => {
    setCreateZone(!CreateZone);
    console.log(CreateZone);
  };
  const StationsHandler = () => {
    setShowStation(!showStation);
  };
  const RemoteControlHandler = () => {
    //write the function to handle Remote Control event here
  };
  const UploadTaskHandler = () => {
    //write the function to handle Upload Task event here
  };
  const AnalyticsHandler = () => {
    //write the function to handle Analytics  event here(may be a redirect)
    navigate("/analytics");
  };
  const TagsHandler = () => {
    navigate("/tags");
  };
  const AIpathHandler = () => {
    setAIpath(!AIpath);
  };
  const clearPathHandler = async () => {
    setPathRecord({
      x: [],
      y: [],
    });
  };
  const handleDeletePath = async (pathname) => {
    if (window.confirm("Are you sure you want to delete this path?")) {
      try {
        await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/deletepath",
          { pathname, companymap }
        );
        alert("Path deleted successfully!");
        setSelectedPath((prevSelectedPath) =>
          prevSelectedPath.filter((path) => path.pathname !== pathname)
        );
        const data = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getpathdata",
          { company: companymap }
        );
        window.sessionStorage.setItem("pathData", JSON.stringify(data.data));
      } catch (error) {
        console.error("Error deleting path:", error);
        alert("Failed to delete path.");
      }
    }
  };

  const handleDeleteStation = async (stationName) => {
    if (window.confirm("Are you sure you want to delete this station?")) {
      try {
        await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/deletestation",
          { stationName, companymap }
        );
        alert("Station deleted successfully!");
        setSelectedStation((prevSelectedStations) =>
          prevSelectedStations.filter(
            (station) => station.stationName !== stationName
          )
        );
        const data = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",
          { company: companyName }
        );
        await window.sessionStorage.setItem(
          "stationData",
          JSON.stringify(data.data["stationdata"])
        );
      } catch (error) {
        console.error("Error deleting station:", error);
        alert("Failed to delete station.");
      }
    }
  };
  const handleDeleteParking = async (stationName) => {
    if (window.confirm("Are you sure you want to delete this parking zone?")) {
      try {
        await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/deletebotparkingspace",
          { station: stationName, companyName }
        );
        alert("Parking deleted successfully!");
        setSelectedParkingSpace((prevSelectedStations) =>
          prevSelectedStations.filter(
            (station) => station.stationName !== stationName
          )
        );
        const data = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",
          { company: companyName }
        );
        await window.sessionStorage.setItem(
          "stationData",
          JSON.stringify(data.data["stationdata"])
        );
      } catch (error) {
        console.error("Error deleting Cart Parking Space:", error);
        alert("Failed to delete Cart Parking Space.");
      }
    }
  };
  const handleDeleteCartParking = async (stationName) => {
    if (
      window.confirm("Are you sure you want to delete this cart parking zone?")
    ) {
      try {
        const state = window.prompt("Enter state as full or empty");
        await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/deletecartparkingspace",
          { stationName, companyName, state }
        );
        alert("Cart Parking deleted successfully!");
        setSelectedParkingSpace((prevSelectedStations) =>
          prevSelectedStations.filter(
            (station) => station.stationName !== stationName
          )
        );
        const data = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",
          { company: companyName }
        );
        await window.sessionStorage.setItem(
          "stationData",
          JSON.stringify(data.data["stationdata"])
        );
      } catch (error) {
        console.error("Error deleting Cart Parking Space:", error);
        alert("Failed to delete Cart Parking Space.");
      }
    }
  };
  const handleDeleteZone = async (zoneName) => {
    if (window.confirm("Are you sure you want to delete this zone?")) {
      try {
        await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/deletezone",
          { zoneName, companymap }
        );
        alert("Zone deleted successfully!");
        setSelectedZone((prevSelectedZones) =>
          prevSelectedZones.filter((zone) => zone.zonename !== zoneName)
        );
        const data = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getzonedata",
          { company: companyName }
        );
        window.sessionStorage.setItem("zoneData", JSON.stringify(data.data));
      } catch (error) {
        console.error("Error deleting zone:", error);
        alert("Failed to delete zone.");
      }
    }
  };

  const pathRecordHandler = async () => {
    const isAISmootheningActive = AIpathArray.x.length !== 0;
    console.log(pathRecord);
    let newpoints = [];
    let pathToSave = {};
    if (!isAISmootheningActive) {
      for (let i = 0; i < pathRecord.x.length; i++) {
        newpoints.push({ x: pathRecord.x[i], y: pathRecord.y[i] });
      }
      let newPoints = await addIntermediatePoints(newpoints, 10);
      let xList = [];
      let yList = [];
      for (let i = 0; i < newPoints.length; i++) {
        xList.push(newPoints[i].x);
        yList.push(newPoints[i].y);
      }
      pathToSave = { x: xList, y: yList };
    } else {
      pathToSave = AIpathArray;
    }
    var scaleFactor = 24.1304520095363,
      originX = 23462.84145899498 / scaleFactor,
      originY = yFlipped * (-55755.01018319527 / scaleFactor);
    if (
      isAISmootheningActive ? pathToSave.x.length === 0 : pathToSave.length == 0
    ) {
      alert("No path to save!");
      return;
    }
    const speed = window.prompt("Enter the speed:");
    const distance = window.prompt("Enter the distance:");
    const companyName = sessionStorage.getItem("companyName");
    const directionality = window.confirm(
      "Select Okay for directed and Cancel for undirected"
    );
    var convertedX = 0;
    var convertedXPath = [];
    var convertedY = 0;
    var convertedYPath = [];

    for (var i = 0; i < pathToSave.x.length; i++) {
      convertedX =
        ((pathToSave.x[i] / xFlipped +
          (xFlipped * img.naturalWidth) / 2 -
          xFlipped * originX) *
          scaleFactor) /
        10;
      convertedY =
        ((pathToSave.y[i] / yFlipped +
          (yFlipped * img.naturalHeight) / 2 -
          yFlipped * originY) *
          scaleFactor) /
        10;

      convertedXPath.push(convertedX);
      convertedYPath.push(convertedY);
    }

    let finalPath = {
      x: [...convertedXPath],
      y: [...convertedYPath],
    };
    if (speed && distance) {
      try {
        const response = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/savepath",
          {
            path: finalPath,
            speed: speed,
            distance: distance,
            companyname: companyName,
            directionality: directionality ? "directed" : "undirected",
          }
        );
        console.log(response);

        setPathRecord({
          x: [],
          y: [],
        });

        // Optionally, you can add a success message or confirmation
        if (response.status === 200) {
          const data = await axios.post(
            "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getpathdata",
            {
              company: companyName,
            }
          );
          window.sessionStorage.setItem("pathData", JSON.stringify(data.data));
          alert("Path saved successfully!");
          setAIpath(false);
          setAIpathArray({
            x: [],
            y: [],
          });

          // Add the new path to selected paths

          setSelectedPath(data.data.paths);
        }
      } catch (err) {
        console.error("Error saving path:", err);
        setPathRecord({
          x: [],
          y: [],
        });
        setAIpath(false);
        setAIpathArray({
          x: [],
          y: [],
        });
      }
    } else {
      alert("Speed and distance are required!");
      setPathRecord({
        x: [],
        y: [],
      });
    }

    setToRecord(false);
  };
  const TracePathSaveHandler = async () => {
    console.log(botTraceArray);

    if (botTraceArray.x.length === 0) {
      alert("No path has been Traced");
      setTracePath(false);
      return;
    }

    const scaleFactor = 24.1304520095363;
    const originX = 23462.84145899498 / scaleFactor;
    const originY = yFlipped * (-55755.01018319527 / scaleFactor);
    const convertedXPath = [];
    const convertedYPath = [];

    for (let i = 0; i < botTraceArray.x.length; i++) {
      const convertedX = botTraceArray.x[i] / 10;
      const convertedY = botTraceArray.y[i] / 10;

      convertedXPath.push(convertedX);
      convertedYPath.push(convertedY);
    }

    const convertedTraceArray = {
      x: convertedXPath.map((value) => value),
      y: convertedYPath.map((value) => value),
    };

    console.log("bot Trace Array:", botTraceArray);
    console.log("Converted Trace Array:", convertedTraceArray);

    // Prompt user for speed and distance
    const speed = window.prompt("Enter the speed:");
    const distance = window.prompt("Enter the distance:");
    const directionality = window.confirm(
      "Select Okay for directed and Cancel for undirected"
    );
    if (speed && distance) {
      try {
        const response = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/savepath",
          {
            path: convertedTraceArray,
            speed: speed,
            distance: distance,
            companyname: companyName,
            directionality: directionality ? "directed" : "undirected",
          }
        );

        console.log("Save Response:", response);

        setBotTraceArray({
          x: [],
          y: [],
        });
        setTracePath(false);

        if (response.status === 200) {
          const data = await axios.post(
            "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getpathdata",
            { company: companyName }
          );
          window.sessionStorage.setItem("pathData", JSON.stringify(data.data));
          alert("Path saved successfully!");
        }
      } catch (err) {
        console.error("Error saving path:", err);
        setTracePath(false);
        setBotTraceArray({
          x: [],
          y: [],
        });
      }
    } else {
      alert("Speed and Distance are required");
      setTracePath(false);
    }
  };

  const clearTracedPathHandler = () => {
    setBotTraceArray({
      x: [],
      y: [],
    });
    setjitterbotCoordinates({
      x: [],
      y: [],
    });
    setTracePath(!tracePath);
  };
  const TracePathHandler = () => {
    const tagName = window.prompt("Enter the tag name to trace:");
    if (tagName) {
      settagName(tagName);
      setTracePath(!tracePath);
      if (tracePath == false) {
        setAIpath(false);
      }
      setAIpathArray({
        x: [],
        y: [],
      });
    } else {
      alert(`Tag with ID "${tagName}" not found!`);
      setAIpath(false);
    }
  };

  const recordStationHandler = () => {
    setShowStationRecord(!showStationRecord);
    if (stationRecord == true) {
      setStationRecord(false);
      setStationMarkedCoordinate({
        x: null,
        y: null,
      });
    } else {
      setStationRecord(true);
    }
  };
  const stationSave = async () => {
    const stationConvertedCoordinates = convertToReal(
      stationMarkedCoordinate.x,
      stationMarkedCoordinate.y
    );
    var nameStation = window.prompt("Enter the name of Station:");
    const companyName = window.sessionStorage.getItem("companyName");
    if (nameStation.length == 0) {
      alert("Station Name cannot be Null");
      return;
    }
    try {
      const res = await axios.post(
        "https://drobot-admin-v2-a2def93839bb.herokuapp.com/savestation",
        {
          stationCoordinate: {
            x: stationConvertedCoordinates.convertedX,
            y: stationConvertedCoordinates.convertedY,
          },
          companyname: companyName,
          stationName: nameStation,
        }
      );
      if (res.status === 200) {
        const data = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",
          { company: companyName }
        );
        await window.sessionStorage.setItem(
          "stationData",
          JSON.stringify(data.data["stationdata"])
        );
        setSelectedStation(
          JSON.parse(window.sessionStorage.getItem("stationData"))
        );
        alert("Station saved successfully!");
        setStationMarkedCoordinate([], []);
      }
    } catch (error) {
      console.log(error);
      setStationMarkedCoordinate([], []);
      alert("error occured while saving station");
    }
    setShowStationRecord(!showStationRecord);
    console.log(
      convertToReal(stationMarkedCoordinate.x, stationMarkedCoordinate.y)
    );
  };
  const ZoneSaveHandler = async () => {
    if (ZonePointArray.x.length < 3) {
      alert("Please select atleast 3 points to create a zone");
      return;
    }

    let convertedZonePointsArray = {
      x: [],
      y: [],
    };
    for (let i = 0; i < ZonePointArray.x.length; i++) {
      const convertedZonePoints = await convertToReal(
        ZonePointArray.x[i],
        ZonePointArray.y[i]
      );

      convertedZonePointsArray.x.push(convertedZonePoints.convertedX);
      convertedZonePointsArray.y.push(convertedZonePoints.convertedY);
    }
    console.log(convertedZonePointsArray);
    convertedZonePointsArray.x = convertedZonePointsArray.x.slice(
      1,
      convertedZonePointsArray.x.length - 1
    );
    convertedZonePointsArray.y = convertedZonePointsArray.y.slice(
      1,
      convertedZonePointsArray.y.length - 1
    );
    const companyName = window.sessionStorage.getItem("companyName");
    const zonename = window.prompt("Enter the name of the Zone:");
    try {
      const res = await axios.post(
        "https://drobot-admin-v2-a2def93839bb.herokuapp.com/savezone",
        {
          zonePoints: convertedZonePointsArray,
          companyname: companyName,
          zoneName: zonename,
        }
      );
      if (res.status === 200) {
        const data = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getzonedata",
          { company: companyName }
        );
        window.sessionStorage.setItem("zoneData", JSON.stringify(data.data));
        setZonePointArray({
          x: [],
          y: [],
        });
        convertedZonePointsArray = {
          x: [],
          y: [],
        };
        if (zoneData) {
          setSelectedZone(data.data.zones);
        } else {
          setSelectedZone([]);
        }
        alert("Zone saved successfully");
      }
    } catch (error) {
      console.log(error);
      setZonePointArray({
        x: [],
        y: [],
      });
      convertedZonePointsArray = {
        x: [],
        y: [],
      };
      alert("error occured while saving zone");
    }
  };

  const clearStationHandler = async () => {
    setStationMarkedCoordinate({
      x: null,
      y: null,
    });
    setStationRecord(true);
  };
  const handleClearBotParkingSpace = () => {
    setParkingSpaceCoordinates({
      ...parkingSpaceCoordinates,
      x: null,
      y: null,
      yaw: null,
    });
    setStartParkingSpaceRecord(true);
  };
  const handleClearCartParkingSpace = () => {
    setCartParkingSpaceCoordinates({
      ...cartParkingSpaceCoordinates,
      P: {
        center: {
          x: null,
          y: null,
        },
        x: [null, null, null, null],
        y: [null, null, null, null],
      },
      P_Prime: {
        x: null,
        y: null,
      },
      P1: {
        x: null,
        y: null,
      },
      P2: {
        x: null,
        y: null,
      },
      yaw: 0,
    });
    setStartCartParkingSpaceRecord(true);
  };

  const handleThemeToggle = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  const PathDrawHandler = () => {
    if (ToRecord == true) {
      setToRecord(false);
      setPathRecord({
        x: [],
        y: [],
      });
    } else {
      setToRecord(true);
    }
  };

  const handleSaveBotParkingSpace = async () => {
    try {
      if (
        parkingSpaceCoordinates.x == null ||
        parkingSpaceCoordinates.y == null ||
        parkingSpaceCoordinates.station == null
      ) {
        console.log(parkingSpaceCoordinates);

        alert("Invalid Data");
        return;
      }
      console.log(parkingSpaceCoordinates);

      const convertedCoordinates = await convertToReal(
        parkingSpaceCoordinates.x,
        parkingSpaceCoordinates.y
      );
      // console.log(convertedCoordinates);

      let angleconversion2 = 0;
      let angleconversion1 = 0;
      //check these angle conversion for quadrants;
      if (parkingSpaceCoordinates.yaw != null) {
        angleconversion1 =
          xFlipped == -1
            ? parkingSpaceCoordinates.yaw < 0
              ? -180 - parkingSpaceCoordinates.yaw
              : 180 - parkingSpaceCoordinates.yaw
            : parkingSpaceCoordinates.yaw;
        angleconversion2 =
          yFlipped == -1 ? -angleconversion1 : angleconversion1;
      }
      // return;
      const data = {
        station: parkingSpaceCoordinates.station,
        x: convertedCoordinates.convertedX,
        y: convertedCoordinates.convertedY,
        yaw: angleconversion2,
      };

      console.log(data);

      const company = window.sessionStorage.getItem("companyName");

      const res = await axios.post(
        "https://drobot-admin-v2-a2def93839bb.herokuapp.com/savebotparkingspace",
        {
          data: data,
          companyName: company,
        }
      );

      if (res.status == 200) {
        const data = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",
          { company: companyName }
        );
        await window.sessionStorage.setItem(
          "stationData",
          JSON.stringify(data.data["stationdata"])
        );
        await window.sessionStorage.setItem(
          "botParkingSpaceData",
          JSON.stringify(
            data.data["stationdata"]
              .map((station) => {
                if (station.bot_parking != null) {
                  return {
                    ...station.bot_parking,
                    station: station.stationName,
                  };
                }
                return null;
              })
              .filter((parking) => parking !== null)
          )
        );
        setSelectedStation(
          JSON.parse(window.sessionStorage.getItem("stationData"))
        );
        setParkingSpaceCoordinates({
          station: null,
          x: null,
          y: null,
          yaw: null,
        });
        setStartParkingSpaceRecord(false);
        alert(
          `Bot parking Space saved successfully for station:${parkingSpaceCoordinates.station}`
        );
        setSelectedParking(
          JSON.parse(window.sessionStorage.getItem("stationData")).filter((parking) => parking.bot_parking)
            
        );
      } else {
        alert("error while saving bot Parking Space");
        setParkingSpaceCoordinates({
          station: null,
          x: null,
          y: null,
          yaw: null,
        });
        setStartParkingSpaceRecord(false);
      }
    } catch (error) {
      console.log(error);
      alert(" error occured");
      setParkingSpaceCoordinates({
        station: null,
        x: null,
        y: null,
        yaw: null,
      });
      setStartParkingSpaceRecord(false);
    }
  };
  const handleSaveCartParkingSpace = async () => {
    try {
      if (
        !cartParkingSpaceCoordinates.P.center.x ||
        !cartParkingSpaceCoordinates.station ||
        !cartParkingSpaceCoordinates.state
      ) {
        console.log(cartParkingSpaceCoordinates);
        alert(
          "Invalid Data. Ensure station, state, and coordinates are properly set."
        );
        return;
      }
      const convertCoordinate = async (x, y) => {
        const { convertedX, convertedY } = await convertToReal(x, y);
        return { x: convertedX, y: convertedY };
      };
      const convertedCenter = await convertCoordinate(
        cartParkingSpaceCoordinates.P.center.x,
        cartParkingSpaceCoordinates.P.center.y
      );
      const convertedPArray = await Promise.all(
        cartParkingSpaceCoordinates.P.x.map((x, i) =>
          convertCoordinate(x, cartParkingSpaceCoordinates.P.y[i])
        )
      );
      const convertedP_Prime = await convertCoordinate(
        cartParkingSpaceCoordinates.P_Prime.x,
        cartParkingSpaceCoordinates.P_Prime.y
      );
      const convertedP1 = await convertCoordinate(
        cartParkingSpaceCoordinates.P1.x,
        cartParkingSpaceCoordinates.P1.y
      );
      const convertedP2 = await convertCoordinate(
        cartParkingSpaceCoordinates.P2.x,
        cartParkingSpaceCoordinates.P2.y
      );
      let angleconversion1 = 0;
      let angleconversion2 = 0;
      if (cartParkingSpaceCoordinates.yaw != null) {
        angleconversion1 =
          xFlipped == -1
            ? cartParkingSpaceCoordinates.yaw < 0
              ? -180 - cartParkingSpaceCoordinates.yaw
              : 180 - cartParkingSpaceCoordinates.yaw
            : cartParkingSpaceCoordinates.yaw;
        angleconversion2 =
          yFlipped == -1 ? -angleconversion1 : angleconversion1;
      }
      const data = {
        P: {
          center: convertedCenter,
          x: convertedPArray.map((point) => point.x),
          y: convertedPArray.map((point) => point.y),
        },
        P_Prime: convertedP_Prime,
        P1: convertedP1,
        P2: convertedP2,
        yaw: angleconversion2,
        station: cartParkingSpaceCoordinates.station,
        state: cartParkingSpaceCoordinates.state,
      };
      const res = await axios.post(
        "https://drobot-admin-v2-a2def93839bb.herokuapp.com/savecartparkingspace",
        {
          data: data,
          companyName: companyName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        const companyDataRes = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",
          { company: companyName }
        );
        const stationData = companyDataRes.data["stationdata"];
        window.sessionStorage.setItem(
          "stationData",
          JSON.stringify(stationData)
        );
        window.sessionStorage.setItem(
          "cartParkingSpaceData",
          JSON.stringify(
            stationData
              .map((station) => {
                if (
                  station.cart_parking_full != null ||
                  station.cart_parking_empty != null
                ) {
                  return {
                    station: station.stationName,
                    full: station.cart_parking_full,
                    empty: station.cart_parking_empty,
                  };
                }
                return null;
              })
              .filter((parking) => parking !== null)
          )
        );
        setSelectedStation(
          JSON.parse(window.sessionStorage.getItem("stationData"))
        );
        setCartParkingSpaceCoordinates({
          station: null,
          state: null,
          P: {
            center: {
              x: null,
              y: null,
            },
            x: [null, null, null, null],
            y: [null, null, null, null],
          },
          P_Prime: {
            x: null,
            y: null,
          },
          P1: {
            x: null,
            y: null,
          },
          P2: {
            x: null,
            y: null,
          },
          yaw: 0,
        });
        setStartCartParkingSpaceRecord(false);

        alert(
          `Cart parking space saved successfully for station: ${data.station}`
        );
        setSelectedCartParking(
          JSON.parse(window.sessionStorage.getItem("stationData")).filter((parking) => parking.cart_parking_full||parking.cart_parking_empty)
            
        );
      } else {
        alert("Error while saving cart parking space.");
        setCartParkingSpaceCoordinates({
          station: null,
          state: null,
          P: {
            center: {
              x: null,
              y: null,
            },
            x: [null, null, null, null],
            y: [null, null, null, null],
          },
          P_Prime: {
            x: null,
            y: null,
          },
          P1: {
            x: null,
            y: null,
          },
          P2: {
            x: null,
            y: null,
          },
          yaw: 0,
        });
        setStartCartParkingSpaceRecord(false);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      alert("An error occurred while saving cart parking space.");
      setCartParkingSpaceCoordinates({
        station: null,
        state: null,
        P: {
          center: {
            x: null,
            y: null,
          },
          x: [null, null, null, null],
          y: [null, null, null, null],
        },
        P_Prime: {
          x: null,
          y: null,
        },
        P1: {
          x: null,
          y: null,
        },
        P2: {
          x: null,
          y: null,
        },
        yaw: 0,
      });
      setStartCartParkingSpaceRecord(false);
    }
  };
  const handleLogout = () => {
    if (!window.confirm("Are you sure you want to logout?")) {
      return;
    }
    localStorage.removeItem("auth");
    sessionStorage.clear();
    alert("Logged out successfully");
    navigate("/login");
  };
  return (
    <>
      <div
        className={` h-screen font-sans transition-all ${
          toggle ? " w-max" : "w-60"
        }`}
        style={{
          backgroundColor: theme === "light" ? "#015D81" : "#0C2F3A",
          position: "sticky",
          top: "0",
          zIndex: "1000",
        }}
        // backgroundColor="015D81"
      >
        <div
          className={`relative left-0 h-screen overflow-hidden transition-all ${
            toggle ? "w-max" : "w-60"
          } `}
          style={{ backgroundColor: theme === "light" ? "#015D81" : "#0C2F3A" }}
          // backgroundColor="015D81"
        >
          <div
            className={` flex flex-wrap items-center justify-between ${
              toggle ? "py-2" : "p-2"
            } gap-2 `}
          >
            <div
              className={`${
                toggle ? "w-[100%]" : " w-max"
              } flex gap-2 justify-evenly items-center`}
            >
              <img
                src={toggle ? Logo : "svgs/drobot_logo.svg"}
                alt="Drobot Logo"
                className={` self-center h-[2.5rem]  `}
              />
            </div>

            <div
              className={`${
                toggle ? "w-[100%]" : "w-auto"
              } flex gap-2 justify-evenly items-center`}
            >
              <button>
                <img
                  src="svgs/pin.svg"
                  alt="Pin"
                  className="w-4 h-4 mr-2 mt-2"
                />
              </button>
              <button
                className="text-white text-xl"
                onClick={() => setToggle(!toggle)}
              >
                <img
                  src={"svgs/toggle.svg"}
                  alt="Toggle"
                  className="w-4 h-4 mt-2"
                />
              </button>
            </div>
          </div>
          {/* UI when SideBar is Open */}
          {!toggle ? (
            <div className=" text-sm w-full text-white p-2 dropdown-container max-h-[calc(100vh-14rem)] overflow-y-auto">
              <div className=" text-[0.7rem] text-[#81B3C7] mt-8 mb-2 ml-3">
                GENERAL
              </div>
              <div>
                <span
                  className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2  justify-between items-center cursor-pointer"
                  onClick={() => setShowList(!showList)}
                >
                  <div className="flex justify-center p-1 items-center gap-2 ">
                    <img
                      src="svgs/show.svg"
                      alt="Toggle"
                      className="w-5.5 h-5.5"
                    />
                    <span>Show</span>
                  </div>
                  <span className="ml-2 ">
                    {showList ? (
                      <img
                        src="svgs/arrow_up.svg"
                        alt="Toggle"
                        className="w-5.5 h-5.5"
                      />
                    ) : (
                      <img
                        src="svgs/arrow_down.svg"
                        alt="Toggle"
                        className="w-5.5 h-5.5"
                      />
                    )}
                  </span>
                </span>
                {showList && (
                  <>
                    <div className="flex hover:bg-[#FFFFFF1F] rounded-md cursor-pointer text-[0.9rem] ml-4 gap-2 px-2 py-1 justify-start items-center">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                      <div>Select all</div>
                    </div>
                    <div className=" mt-2 ml-4 mb-2 list-none">
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={showBots}
                            onChange={(e) => {
                              BotsHandler(e);
                              const filteredBots = e.target.checked
                                ? Object.keys(botMapping)
                                    .filter((tagId) =>
                                      tags.hasOwnProperty(tagId)
                                    )
                                    .map((tagId) => botMapping[tagId])
                                : [];
                              setSelectedBot(filteredBots);
                            }}
                          />
                          <img
                            src="svgs/bots.svg"
                            alt="Bots"
                            className="w-[22px] h-[22px]"
                          />
                          <span>Bots</span>
                        </div>
                        <button
                          onClick={() =>
                            setshowIndividualBot(!showIndividualBot)
                          }
                        >
                          {showIndividualBot ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>
                      {showIndividualBot && botMapping && tags && (
                        <ul className="path-list rounded-md pl-6 mr-2 mb-4">
                          {Object.keys(tags)
                            .filter((tagId) => botMapping[tagId])
                            .map((tagId, index) => {
                              const botName = botMapping[tagId];
                              return (
                                <li
                                  key={index}
                                  className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                                  onClick={() => {
                                    setSelectedBot((prevBots) => {
                                      const isBotSelected =
                                        prevBots.includes(botName);
                                      let newBots;

                                      if (isBotSelected) {
                                        newBots = prevBots.filter(
                                          (b) => b !== botName
                                        );
                                      } else {
                                        newBots = [...prevBots, botName];
                                      }
                                      const allBotsSelected = Object.keys(tags)
                                        .map((id) => botMapping[id])
                                        .filter(Boolean)
                                        .every((name) =>
                                          newBots.includes(name)
                                        );

                                      setShowBots(allBotsSelected);
                                      return newBots;
                                    });
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectedBot.includes(botName)}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      const isChecked = e.target.checked;
                                      setSelectedBot((prevBots) => {
                                        let newBots;
                                        if (isChecked) {
                                          newBots = [...prevBots, botName];
                                        } else {
                                          newBots = prevBots.filter(
                                            (b) => b !== botName
                                          );
                                        }
                                        const allBotsSelected = Object.keys(
                                          tags
                                        )
                                          .map((id) => botMapping[id])
                                          .filter(Boolean)
                                          .every((name) =>
                                            newBots.includes(name)
                                          );
                                        setShowBots(allBotsSelected);
                                        return newBots;
                                      });
                                    }}
                                    className="mr-2"
                                  />
                                  {botName}
                                </li>
                              );
                            })}
                        </ul>
                      )}
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={showpath}
                            onChange={(e) => {
                              PathsHandler(e);
                              setSelectedPath(e.target.checked ? paths : []); // Select all paths if checked, deselect all if unchecked
                            }}
                          />
                          <img
                            src="svgs/paths.svg"
                            alt="Paths"
                            className="w-[22px] h-[22px]"
                          />
                          <span>Paths</span>
                        </div>
                        <button
                          onClick={() =>
                            setshowIndividualPath(!showIndividualPath)
                          }
                        >
                          {showIndividualPath ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>

                      {showIndividualPath && paths && paths.length > 0 && (
                        <ul className="path-list rounded-md pl-6 mr-2 mb-4">
                          {paths.map((path, index) => (
                            <li
                              key={index}
                              className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                              onMouseEnter={() => setHoveredPath(path)} // Set the hovered path
                              onMouseLeave={() => setHoveredPath(null)} // Clear the hovered path
                              onClick={() => {
                                setSelectedPath((prevPaths) => {
                                  const isPathSelected = prevPaths.some(
                                    (p) => p.pathname === path.pathname
                                  );
                                  let newPaths;

                                  if (isPathSelected) {
                                    newPaths = prevPaths.filter(
                                      (p) => p.pathname !== path.pathname
                                    );
                                  } else {
                                    newPaths = [...prevPaths, path];
                                  }

                                  const allPathsSelected =
                                    paths.length > 0 &&
                                    paths.every((p) =>
                                      newPaths.some(
                                        (np) => np.pathname === p.pathname
                                      )
                                    );
                                  setShowpath(allPathsSelected); // If all paths are selected, mark showpath as true
                                  return newPaths;
                                });
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  !!selectedPath.find(
                                    (p) => p.pathname === path.pathname
                                  )
                                } // Check if the path is in selectedPath
                                onChange={(e) => {
                                  e.stopPropagation();
                                  const isChecked = e.target.checked;
                                  setSelectedPath((prevPaths) => {
                                    if (isChecked) {
                                      const newPaths = [...prevPaths, path];
                                      const allPathsSelected =
                                        paths.length > 0 &&
                                        paths.every((p) =>
                                          newPaths.some(
                                            (np) => np.pathname === p.pathname
                                          )
                                        );
                                      setShowpath(allPathsSelected); // If all paths are selected, mark showpath as true
                                      return newPaths;
                                    } else {
                                      const newPaths = prevPaths.filter(
                                        (p) => p.pathname !== path.pathname
                                      );
                                      setShowpath(false); // Since one path is unchecked, uncheck showpath
                                      return newPaths;
                                    }
                                  });
                                }}
                                className="mr-2"
                              />
                              {path.pathname}
                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent onClick for the parent <li>
                                  handleDeletePath(path.pathname);
                                }}
                                className="ml-auto text-red-500"
                              >
                                Delete
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}

                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={showPeople}
                            onChange={(e) => {
                              PeopleHandler(e);

                              // Filter peopleMapping to include only those whose tagId exists in tags
                              const filteredPeople = e.target.checked
                                ? Object.keys(peopleMapping)
                                    .filter((tagId) =>
                                      tags.hasOwnProperty(tagId)
                                    ) // Check if tagId exists in tags
                                    .map((tagId) => peopleMapping[tagId]) // Get the corresponding person's name
                                : [];

                              setSelectedPeople(filteredPeople); // Set only the filtered names
                            }}
                          />
                          <img
                            src="svgs/people.svg"
                            alt="People"
                            className="w-[22px] h-[22px]"
                          />
                          <span>People</span>
                        </div>
                        <button
                          onClick={() =>
                            setshowIndividualPeople(!showIndividualPeople)
                          }
                        >
                          {showIndividualPeople ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>
                      {showIndividualPeople && peopleMapping && tags && (
                        <ul className="path-list rounded-md pl-6 mr-2 mb-4">
                          {Object.keys(tags)
                            .filter((tagId) => peopleMapping[tagId])
                            .map((tagId, index) => {
                              const person = tags[tagId];
                              return (
                                <li
                                  key={index}
                                  className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                                  onClick={() => {
                                    setSelectedPeople((prevPeople) => {
                                      const personName = peopleMapping[tagId];
                                      const isPersonSelected =
                                        prevPeople.includes(personName);
                                      let newPeople;
                                      if (isPersonSelected) {
                                        newPeople = prevPeople.filter(
                                          (p) => p !== personName
                                        );
                                      } else {
                                        newPeople = [...prevPeople, personName];
                                      }
                                      const allPeopleWithTags = Object.keys(
                                        tags
                                      )
                                        .map((id) => peopleMapping[id])
                                        .filter(Boolean);
                                      const allSelected =
                                        allPeopleWithTags.every((name) =>
                                          newPeople.includes(name)
                                        );
                                      setShowPeople(allSelected);
                                      return newPeople;
                                    });
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectedPeople.includes(
                                      peopleMapping[tagId]
                                    )}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      const isChecked = e.target.checked;
                                      setSelectedPeople((prevPeople) => {
                                        const personName = peopleMapping[tagId];
                                        let newPeople;

                                        if (isChecked) {
                                          newPeople = [
                                            ...prevPeople,
                                            personName,
                                          ];
                                        } else {
                                          newPeople = prevPeople.filter(
                                            (p) => p !== personName
                                          );
                                        }
                                        const allPeopleWithTags = Object.keys(
                                          tags
                                        )
                                          .map((id) => peopleMapping[id])
                                          .filter(Boolean);
                                        const allSelected =
                                          allPeopleWithTags.every((name) =>
                                            newPeople.includes(name)
                                          );
                                        setShowPeople(allSelected);
                                        return newPeople;
                                      });
                                    }}
                                    className="mr-2"
                                  />
                                  {peopleMapping[tagId]}{" "}
                                </li>
                              );
                            })}
                        </ul>
                      )}
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={Forklifts}
                            onChange={ForkliftsHandler}
                          />
                          <img
                            src="svgs/forklifts.svg"
                            alt="Forklifts"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Forklifts</span>
                        </div>
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      </button>
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={showMaterials}
                            onChange={MaterialsHandler}
                          />
                          <img
                            src="svgs/materials.svg"
                            alt="Materials"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Materials</span>
                        </div>
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      </button>
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={
                              stationData.length > 0 &&
                              JSON.parse(stationData).every((station) =>
                                selectedStation.some(
                                  (s) => s.stationName === station.stationName
                                )
                              )
                            }
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setShowStation(isChecked); // Set the showStation checkbox state
                              setSelectedStation(
                                isChecked ? JSON.parse(stationData) : []
                              ); // Select all stations if checked, deselect all if unchecked
                            }}
                          />
                          <img
                            src="svgs/stations.svg"
                            alt="Stations"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Stations</span>
                        </div>
                        <button
                          onClick={() =>
                            setshowIndividualStation(!showIndividualStation)
                          }
                        >
                          {showIndividualStation ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>

                      {showIndividualStation &&
                        stationData &&
                        JSON.parse(stationData).length > 0 && (
                          <ul className="station-list rounded-md pl-6 mr-2 mb-4">
                            {JSON.parse(stationData).map((station, index) => (
                              <li
                                key={index}
                                className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                                onClick={() => {
                                  setSelectedStation((prevStations) => {
                                    const isStationSelected = prevStations.some(
                                      (s) =>
                                        s.stationName === station.stationName
                                    );
                                    let newStations;

                                    if (isStationSelected) {
                                      newStations = prevStations.filter(
                                        (s) =>
                                          s.stationName !== station.stationName
                                      );
                                    } else {
                                      newStations = [...prevStations, station];
                                    }

                                    // Update the `showStation` state
                                    const allStationsSelected =
                                      stationData.length > 0 &&
                                      JSON.parse(stationData).every((s) =>
                                        newStations.some(
                                          (ns) =>
                                            ns.stationName === s.stationName
                                        )
                                      );
                                    setShowStation(allStationsSelected); // If all stations are selected, mark showStation as true
                                    return newStations;
                                  });
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    !!selectedStation.find(
                                      (s) =>
                                        s.stationName === station.stationName
                                    )
                                  } // Check if the station is in selectedStation
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    const isChecked = e.target.checked;
                                    setSelectedStation((prevStations) => {
                                      if (isChecked) {
                                        const newStations = [
                                          ...prevStations,
                                          station,
                                        ];
                                        const allStationsSelected =
                                          stationData.length > 0 &&
                                          JSON.parse(stationData).every((s) =>
                                            newStations.some(
                                              (ns) =>
                                                ns.stationName === s.stationName
                                            )
                                          );
                                        setShowStation(allStationsSelected); // If all stations are selected, mark showStation as true
                                        return newStations;
                                      } else {
                                        const newStations = prevStations.filter(
                                          (s) =>
                                            s.stationName !==
                                            station.stationName
                                        );
                                        setShowStation(false); // Since one station is unchecked, uncheck showStation
                                        return newStations;
                                      }
                                    });
                                  }}
                                  className="mr-2"
                                />
                                {station.stationName}
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent onClick for the parent <li>
                                    handleDeleteStation(station.stationName);
                                  }}
                                  className="ml-auto text-red-500"
                                >
                                  Delete
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}

                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={showZone}
                            onChange={(e) => {
                              ZonesHandler(e);
                              setSelectedZone(e.target.checked ? zones : []); // Select all zones if checked, deselect all if unchecked
                            }}
                          />
                          <img
                            src="svgs/zones.svg"
                            alt="Zones"
                            className="w-[22px] h-[22px]"
                          />
                          <span>Zones</span>
                        </div>
                        <button
                          onClick={() =>
                            setShowIndividualZone(!showIndividualZone)
                          }
                        >
                          {showIndividualZone ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>

                      {showIndividualZone && zones && zones.length > 0 && (
                        <ul className="zone-list rounded-md pl-6 mr-2 mb-4">
                          {zones.map((zone, index) => (
                            <li
                              key={index}
                              className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                              onClick={() => {
                                setSelectedZone((prevZones) => {
                                  const isZoneSelected = prevZones.some(
                                    (z) => z.zonename === zone.zonename
                                  );
                                  let newZones;

                                  if (isZoneSelected) {
                                    newZones = prevZones.filter(
                                      (z) => z.zonename !== zone.zonename
                                    );
                                  } else {
                                    newZones = [...prevZones, zone];
                                  }

                                  const allZonesSelected =
                                    zones.length > 0 &&
                                    zones.every((z) =>
                                      newZones.some(
                                        (nz) => nz.zonename === z.zonename
                                      )
                                    );
                                  setShowZone(allZonesSelected); // If all zones are selected, mark showZone as true
                                  return newZones;
                                });
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  !!selectedZone.find(
                                    (z) => z.zonename === zone.zonename
                                  )
                                } // Check if the zone is in selectedZone
                                onChange={(e) => {
                                  e.stopPropagation();
                                  const isChecked = e.target.checked;
                                  setSelectedZone((prevZones) => {
                                    if (isChecked) {
                                      const newZones = [...prevZones, zone];
                                      const allZonesSelected =
                                        zones.length > 0 &&
                                        zones.every((z) =>
                                          newZones.some(
                                            (nz) => nz.zonename === z.zonename
                                          )
                                        );
                                      setShowZone(allZonesSelected); // If all zones are selected, mark showZone as true
                                      return newZones;
                                    } else {
                                      const newZones = prevZones.filter(
                                        (z) => z.zonename !== zone.zonename
                                      );
                                      setShowZone(false); // Since one zone is unchecked, uncheck showZone
                                      return newZones;
                                    }
                                  });
                                }}
                                className="mr-2"
                              />
                              {zone.zonename}
                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent onClick for the parent <li>
                                  handleDeleteZone(zone.zonename);
                                }}
                                className="ml-auto text-red-500"
                              >
                                Delete
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={
                              cartParkingData.length > 0 &&
                              JSON.parse(cartParkingData)
                                .filter((parking) => parking.bot_parking)
                                .every((parking) =>
                                  selectedParking.some(
                                    (s) => s.stationName === parking.stationName
                                  )
                                )
                            }
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setShowParking(isChecked); // Set the showCartParking checkbox state
                              setSelectedParking(
                                isChecked ? JSON.parse(cartParkingData) : []
                              ); // Select all cart parking if checked, deselect all if unchecked
                            }}
                          />
                          <img
                            src="svgs/stations.svg"
                            alt="Bot Parking"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Parking</span>
                        </div>
                        <button
                          onClick={() =>
                            setShowIndividualParking(!showIndividualParking)
                          }
                        >
                          {showIndividualParking ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>

                      {showIndividualParking &&
                        cartParkingData &&
                        JSON.parse(cartParkingData).filter(
                          (Parking) => Parking.bot_parking
                        ).length > 0 && (
                          <ul className="cart-parking-list rounded-md pl-6 mr-2 mb-4">
                            {JSON.parse(cartParkingData)
                              .filter((Parking) => Parking.bot_parking)
                              .map((Parking, index) => (
                                <li
                                  key={index}
                                  className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                                  onClick={() => {
                                    setSelectedParking((prevParkings) => {
                                      const isCartParkingSelected =
                                        prevParkings.some(
                                          (s) =>
                                            s.stationName ===
                                            Parking.stationName
                                        );
                                      let newParkings;
                                      if (isCartParkingSelected) {
                                        newParkings = prevParkings.filter(
                                          (s) =>
                                            s.stationName !==
                                            Parking.stationName
                                        );
                                      } else {
                                        newParkings = [
                                          ...prevParkings,
                                          Parking,
                                        ];
                                      }

                                      // Update the `showCartParking` state
                                      const allParkingsSelected =
                                        cartParkingData.length > 0 &&
                                        JSON.parse(cartParkingData).every((s) =>
                                          newParkings.some(
                                            (ns) =>
                                              ns.stationName === s.stationName
                                          )
                                        );
                                      setShowParking(allParkingsSelected); // If all cart parkings are selected, mark showCartParking as true
                                      return newParkings;
                                    });
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      !!selectedParking.find(
                                        (s) =>
                                          s.stationName === Parking.stationName
                                      )
                                    }
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      const isChecked = e.target.checked;
                                      setSelectedParking((prevParkings) => {
                                        if (isChecked) {
                                          const newParkings = [
                                            ...prevParkings,
                                            Parking,
                                          ];
                                          const allParkingsSelected =
                                            cartParkingData.length > 0 &&
                                            JSON.parse(cartParkingData).every(
                                              (s) =>
                                                newParkings.some(
                                                  (ns) =>
                                                    ns.stationName ===
                                                    s.stationName
                                                )
                                            );
                                          setShowParking(allParkingsSelected); // If all cart parkings are selected, mark showCartParking as true
                                          return newParkings;
                                        } else {
                                          const newParkings =
                                            prevParkings.filter(
                                              (s) =>
                                                s.stationName !==
                                                Parking.stationName
                                            );
                                          setShowParking(false); // Since one cart parking is unchecked, uncheck showCartParking
                                          return newParkings;
                                        }
                                      });
                                    }}
                                    className="mr-2"
                                  />
                                  {Parking.stationName}
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteParking(Parking.stationName);
                                    }}
                                    className="ml-auto text-red-500"
                                  >
                                    Delete
                                  </button>
                                </li>
                              ))}
                          </ul>
                        )}
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={
                              cartParkingData.length > 0 &&
                              JSON.parse(cartParkingData)
                                .filter(
                                  (cartparking) =>
                                    cartparking.cart_parking_full ||
                                    cartparking.cart_parking_empty
                                )
                                .every((cartparking) =>
                                  selectedCartParking.some(
                                    (s) =>
                                      s.stationName === cartparking.stationName
                                  )
                                )
                            }
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setShowCartParking(isChecked); // Set the showCartParking checkbox state
                              setSelectedCartParking(
                                isChecked ? JSON.parse(cartParkingData) : []
                              ); // Select all cart parking if checked, deselect all if unchecked
                            }}
                          />
                          <img
                            src="svgs/stations.svg"
                            alt="Cart Parking"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Cart Parking</span>
                        </div>
                        <button
                          onClick={() =>
                            setShowIndividualCartParking(
                              !showIndividualCartParking
                            )
                          }
                        >
                          {showIndividualCartParking ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>

                      {showIndividualCartParking &&
                        cartParkingData &&
                        JSON.parse(cartParkingData).filter(
                          (cartParking) =>
                            cartParking.cart_parking_full ||
                            cartParking.cart_parking_empty
                        ).length > 0 && (
                          <ul className="cart-parking-list rounded-md pl-6 mr-2 mb-4">
                            {JSON.parse(cartParkingData)
                              .filter(
                                (cartParking) =>
                                  cartParking.cart_parking_full ||
                                  cartParking.cart_parking_empty
                              )
                              .map((cartParking, index) => (
                                <li
                                  key={index}
                                  className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                                  onClick={() => {
                                    setSelectedCartParking(
                                      (prevCartParkings) => {
                                        const isCartParkingSelected =
                                          prevCartParkings.some(
                                            (s) =>
                                              s.stationName ===
                                              cartParking.stationName
                                          );
                                        let newCartParkings;
                                        if (isCartParkingSelected) {
                                          newCartParkings =
                                            prevCartParkings.filter(
                                              (s) =>
                                                s.stationName !==
                                                cartParking.stationName
                                            );
                                        } else {
                                          newCartParkings = [
                                            ...prevCartParkings,
                                            cartParking,
                                          ];
                                        }

                                        // Update the `showCartParking` state
                                        const allCartParkingsSelected =
                                          cartParkingData.length > 0 &&
                                          JSON.parse(cartParkingData).every(
                                            (s) =>
                                              newCartParkings.some(
                                                (ns) =>
                                                  ns.stationName ===
                                                  s.stationName
                                              )
                                          );
                                        setShowCartParking(
                                          allCartParkingsSelected
                                        ); // If all cart parkings are selected, mark showCartParking as true
                                        return newCartParkings;
                                      }
                                    );
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      !!selectedCartParking.find(
                                        (s) =>
                                          s.stationName ===
                                          cartParking.stationName
                                      )
                                    } // Check if the cart parking is in selectedCartParking
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      const isChecked = e.target.checked;
                                      setSelectedCartParking(
                                        (prevCartParkings) => {
                                          if (isChecked) {
                                            const newCartParkings = [
                                              ...prevCartParkings,
                                              cartParking,
                                            ];
                                            const allCartParkingsSelected =
                                              cartParkingData.length > 0 &&
                                              JSON.parse(cartParkingData).every(
                                                (s) =>
                                                  newCartParkings.some(
                                                    (ns) =>
                                                      ns.stationName ===
                                                      s.stationName
                                                  )
                                              );
                                            setShowCartParking(
                                              allCartParkingsSelected
                                            ); // If all cart parkings are selected, mark showCartParking as true
                                            return newCartParkings;
                                          } else {
                                            const newCartParkings =
                                              prevCartParkings.filter(
                                                (s) =>
                                                  s.stationName !==
                                                  cartParking.stationName
                                              );
                                            setShowCartParking(false); // Since one cart parking is unchecked, uncheck showCartParking
                                            return newCartParkings;
                                          }
                                        }
                                      );
                                    }}
                                    className="mr-2"
                                  />
                                  {cartParking.stationName}
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent onClick for the parent <li>
                                      handleDeleteCartParking(
                                        cartParking.stationName
                                      );
                                    }}
                                    className="ml-auto text-red-500"
                                  >
                                    Delete
                                  </button>
                                </li>
                              ))}
                          </ul>
                        )}
                    </div>
                  </>
                )}
              </div>
              <div>
                <span
                  className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer"
                  onClick={() => setRecordList(!recordList)}
                >
                  <div className="flex justify-center items-center gap-2 p-1">
                    <img
                      src="svgs/record.svg"
                      alt="Record"
                      className="w-5.5 h-5.5"
                    />
                    <span>Record</span>
                  </div>
                  <span>
                    {recordList ? (
                      <img
                        src="svgs/arrow_up.svg"
                        alt="Toggle"
                        className="w-5.5 h-5.5"
                      />
                    ) : (
                      <img
                        src="svgs/arrow_down.svg"
                        alt="Toggle"
                        className="w-5.5 h-5.5"
                      />
                    )}
                  </span>
                </span>
                {recordList && (
                  <div className=" ml-7 mb-3 list-none">
                    <button
                      onClick={PathDrawHandler}
                      className={`flex hover:bg-[#FFFFFF1F] ${
                        ToRecord && `bg-[#FFFFFF1F] border rounded-md`
                      } w-full rounded-md p-2 justify-between items-center cursor-pointer`}
                    >
                      <div className="flex gap-[0.5rem] ">
                        <img
                          src="svgs/path_d.svg"
                          alt="Path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Path (draw)</span>
                      </div>
                      {ToRecord ? (
                        <img
                          src="svgs/arrow_down.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      )}
                    </button>
                    {ToRecord && (
                      <div className="pl-4">
                        <button
                          onClick={pathRecordHandler}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </button>

                        <button
                          onClick={AIpathHandler}
                          className={`flex w-full rounded-md p-2 justify-between items-center cursor-pointer text-sm ${
                            AIpath ? "bg-[#FFFFFF1F]" : "hover:bg-[#FFFFFF1F]"
                          }`}
                        >
                          <div className="flex gap-[0.5rem]">
                            <img
                              src="svgs/path_t.svg"
                              alt="path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>AI Smootheing</span>
                          </div>
                        </button>
                        <button
                          onClick={clearPathHandler}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </button>
                      </div>
                    )}
                    <button
                      onClick={TracePathHandler}
                      className={`flex  hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer ${
                        tracePath && `bg-[#FFFFFF1F] border rounded-md`
                      }`}
                    >
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/path_w.svg"
                          alt="path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Path (Trace)</span>
                      </div>
                      {tracePath ? (
                        <img
                          src="svgs/arrow_down.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      )}
                    </button>

                    {tracePath && (
                      <div className="pl-4">
                        <button
                          onClick={TracePathSaveHandler}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </button>
                        <button
                          onClick={AIpathHandler}
                          className={`flex w-full rounded-md p-2 justify-between items-center cursor-pointer text-sm ${
                            AIpath ? "bg-[#FFFFFF1F]" : "hover:bg-[#FFFFFF1F]"
                          }`}
                        >
                          <div className="flex gap-[0.5rem]">
                            <img
                              src="svgs/path_t.svg"
                              alt="path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>AI Smootheing</span>
                          </div>
                        </button>
                        <button
                          onClick={clearTracedPathHandler}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </button>
                      </div>
                    )}
                    <button
                      onClick={handleParkingSpaceRecord}
                      className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between cursor-pointer"
                    >
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/path_w.svg"
                          alt="path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Parking Space</span>
                      </div>
                      {startParkingSpaceRecord ? (
                        <img
                          src="svgs/arrow_down.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      )}
                    </button>
                    {startParkingSpaceRecord && (
                      <div className="pl-4">
                        <button
                          onClick={handleSaveBotParkingSpace}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </button>
                        {/* add clear parking space (bot) button */}
                        <button
                          onClick={handleClearBotParkingSpace}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </button>
                      </div>
                    )}

                    <button
                      onClick={recordStationHandler}
                      className={`flex ${
                        showStationRecord && `bg-[#FFFFFF1F]`
                      } hover:bg-[#FFFFFF1F]  w-full rounded-md p-2 justify-between items-center cursor-pointer`}
                    >
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/station_d.svg"
                          alt="station"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Station (draw)</span>
                      </div>
                      {showStationRecord ? (
                        <img
                          src="svgs/arrow_up.svg"
                          alt="Toggle"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5 cursor-pointer"
                        />
                      )}
                    </button>
                    {showStationRecord && (
                      <ul className="path-list duration-100 rounded-md pl-6  mb-4">
                        <li
                          onClick={clearStationHandler}
                          className={`text-white py-1 pl-2 hover:bg-[#FFFFFF1F] rounded-md ${stationRecord}`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </li>
                        <li
                          onClick={stationSave}
                          className="text-white py-1 pl-2 hover:bg-[#FFFFFF1F] rounded-md"
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </li>
                      </ul>
                    )}
                    <button
                      onClick={handleCartParkingSpaceRecord}
                      className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between cursor-pointer"
                    >
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/path_w.svg"
                          alt="path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Cart Parking Space</span>
                      </div>
                      {startCartParkingSpaceRecord ? (
                        <img
                          src="svgs/arrow_down.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      )}
                    </button>
                    {startCartParkingSpaceRecord && (
                      <div className="pl-4">
                        <button
                          onClick={handleSaveCartParkingSpace}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </button>
                        <button
                          onClick={handleClearCartParkingSpace}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </button>
                      </div>
                    )}
                    <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/station_t.svg"
                          alt="station"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Station (tag)</span>
                      </div>
                      <img
                        src="svgs/arrow_right.svg"
                        alt="Arrow"
                        className="w-5.5 h-5.5"
                      />
                    </button>
                    <button
                      className={`flex hover:bg-[#FFFFFF1F]  w-full ${
                        CreateZone
                          ? "bg-[#FFFFFF1F] border rounded-md"
                          : "bg-none"
                      } rounded-md p-2 justify-between items-center cursor-pointer `}
                      onClick={ZoneClickHandler}
                    >
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/zone.svg"
                          alt="zone"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Zone</span>
                      </div>

                      <img
                        src="svgs/arrow_right.svg"
                        alt="Arrow"
                        className="w-5.5 h-5.5"
                      />
                    </button>
                    {CreateZone && (
                      <ul className="path-list duration-100 rounded-md pl-6  mb-4">
                        <li
                          onClick={() => {
                            setZonePointArray({
                              x: [],
                              y: [],
                            });
                          }}
                          className={`text-white py-1 pl-2 hover:bg-[#FFFFFF1F] rounded-md ${stationRecord}`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </li>
                        <li
                          onClick={ZoneSaveHandler}
                          className="text-white py-1 pl-2 hover:bg-[#FFFFFF1F] rounded-md"
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </li>
                      </ul>
                    )}
                  </div>
                )}
              </div>
              <button
                onClick={() => RemoteControlHandler()}
                className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md pl-3 px-2 py-3 gap-2 cursor-pointer  items-center"
              >
                <img
                  src="svgs/remote_control.svg"
                  alt="Remote"
                  className="w-5.5 h-5.5"
                />
                <span>Remote control</span>
              </button>
              <button
                onClick={() => UploadTaskHandler()}
                className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md pl-3 px-2 py-3 gap-2 cursor-pointer  items-center"
              >
                <img
                  src="svgs/upload_task.svg"
                  alt="Upload"
                  className="w-5.5 h-5.5"
                />
                <span>Upload Task</span>
              </button>
              <button
                onClick={() => AnalyticsHandler()}
                className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md pl-3 px-2 py-3 gap-2 cursor-pointer  items-center"
              >
                <img
                  src="svgs/analytics.svg"
                  alt="Analytics"
                  className="w-5.5 h-5.5"
                />
                <span>Analytics</span>
              </button>
              <button
                onClick={() => TagsHandler()}
                className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md pl-3 px-2 py-3 gap-2 cursor-pointer  items-center"
              >
                <img src="svgs/bots.svg" alt="Tags" className="w-5.5 h-5.5" />
                <span>Tags (Management)</span>
              </button>
              <div className=" text-[0.7rem] text-[#81B3C7] mt-8 mb-2 ml-3">
                ADMIN
              </div>
              <button className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md  gap-2 cursor-pointer p-3">
                <img
                  src="svgs/settings.svg"
                  alt="Settings"
                  className="w-5.5 h-5.5"
                />
                <span>Settings</span>
              </button>
            </div>
          ) : (
            // UI when SideBar is Closed

            <div className=" text-sm flex flex-col text-white p-2 dropdown-container max-h-[calc(100vh-12rem)] justify-center  overflow-y-auto">
              <button
                className="flex hover:bg-[#FFFFFF1F] rounded-md h-[100%] p-2 justify-center"
                onClick={() => {
                  setToggle(!toggle);
                  setShowList(true);
                }}
              >
                <img
                  src="svgs/show.svg"
                  alt="Toggle"
                  className="w-5.5 h-[22px] mt-3"
                />
              </button>
              <button
                className="flex hover:bg-[#FFFFFF1F] rounded-md h-[100%] p-2 justify-center"
                onClick={() => {
                  setToggle(!toggle);
                  setRecordList(true);
                }}
              >
                <img
                  src="svgs/record.svg"
                  alt="Record"
                  className="w-5.5 h-[22px] mt-3"
                />
              </button>
              <button
                className="flex hover:bg-[#FFFFFF1F] rounded-md h-[100%] p-2 justify-center"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <img
                  src="svgs/remote_control.svg"
                  alt="Remote"
                  className="w-5.5 h-[22px] mt-3"
                />
              </button>
              <button
                className="flex hover:bg-[#FFFFFF1F] rounded-md h-[100%] p-2 justify-center"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <img
                  src="svgs/upload_task.svg"
                  alt="Upload"
                  className="w-5.5 h-[22px] mt-3"
                />
              </button>
              <button
                className="flex hover:bg-[#FFFFFF1F] rounded-md h-[100%] p-2 justify-center"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <img
                  src="svgs/analytics.svg"
                  alt="Analytics"
                  className="w-5.5 h-[22px] mt-3"
                />
              </button>
              <button
                className="flex hover:bg-[#FFFFFF1F] rounded-md h-[100%] p-2 justify-center mt-3"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <img
                  src="svgs/settings.svg"
                  alt="Settings"
                  className="w-5.5 h-[22px] mt-7 "
                />
              </button>
            </div>
          )}
          <div className=" justify-center items-center absolute bottom-4 w-full mt-2 p-4 text-white ">
            <hr className="border-[#4290AF] pb-6"></hr>
            <div
              className={`profile flex  justify-between items-center content-between  ${
                toggle && "flex-col justify-center items-center gap-4"
              } `}
            >
              <img
                src={CompanyLogo}
                alt="Profile"
                className="w-12 h-12 rounded-full bg-white p-0.5"
              />
              {!toggle && (
                <div>
                  <div className="profile-info flex flex-col m-1">
                    <div className="font-sans text-l">{companyName}</div>
                    <div className=" font-sans max-w-28 text-left text-xs text-custom-blue">
                      {userEmail.length > 20
                        ? userEmail.slice(0, 10) + "...." + userEmail.slice(-10)
                        : userEmail}
                      ;
                    </div>
                  </div>
                </div>
              )}
              <button className="" onClick={handleLogout}>
                <img
                  src="svgs/logout.svg"
                  alt="Logout"
                  className=" h-[1.4rem]"
                />
              </button>
            </div>
            <div className="theme-toggle mt-0 flex justify-center">
              {
                <button onClick={handleThemeToggle} className="px-2 rounded-md">
                  {toggle === false ? (
                    theme === "dark" ? (
                      <img
                        src="svgs/dark.svg"
                        alt="Dark Mode"
                        className="w-[180%] mr-1 img-full"
                      />
                    ) : (
                      <img
                        src="svgs/light.svg"
                        alt="Light Mode"
                        className="w-[180%] mr-1 img-full"
                      />
                    )
                  ) : theme === "dark" ? (
                    <img
                      src="svgs/smallLDModenew.svg"
                      alt="Dark Mode"
                      className="w-[200%] mr-1 img-full"
                    />
                  ) : (
                    <img
                      src="svgs/smallLDMode.svg"
                      alt="Light Mode"
                      className=" w-[200%] mr-1 img-full"
                    />
                  )}
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
