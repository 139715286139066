export function addIntermediatePoints(points, thresholdDistance) {
    
    const newPoints = [points[0]]; // Start with the first point

    for (let i = 1; i < points.length; i++) {
      const prevPoint = newPoints[newPoints.length - 1];
      const currPoint = points[i];

      const distance = Math.sqrt(
        (currPoint.x - prevPoint.x) ** 2 + (currPoint.y - prevPoint.y) ** 2
      );

      if (distance > thresholdDistance) {
        // Calculate the number of points to add
        const numPointsToAdd = Math.ceil(distance / thresholdDistance) - 1;

        // Calculate x and y increments for each intermediate point
        const xIncrement = (currPoint.x - prevPoint.x) / (numPointsToAdd + 1);
        const yIncrement = (currPoint.y - prevPoint.y) / (numPointsToAdd + 1);

        // Add intermediate points
        for (let j = 1; j <= numPointsToAdd; j++) {
          newPoints.push({
            x: prevPoint.x + xIncrement * j,
            y: prevPoint.y + yIncrement * j,
          });
        }
      }

      newPoints.push(currPoint);
    }
    console.log(newPoints);
    
    return newPoints;
  }