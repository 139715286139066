import React, { useEffect, useState, useMemo } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useTheme } from "../Context/Context.js";
import SummaryCard from "../charts/SummaryCard.js";
import TaskSummary from "../charts/TaskSummary.js";
import TaskByStation from "../charts/TaskByStation.js";
import { ButtonGroup, Button } from "@mui/material";
import { styled } from "@mui/system";
import SideBar from "./SideBar.js";
import axios from "axios";

import logo1 from "../../utils/logos/logo1.png";
import logo2 from "../../utils/logos/logo2.png";
import logo3 from "../../utils/logos/logo3.png";
import logo4 from "../../utils/logos/logo4.png";
import logo5 from "../../utils/logos/logo5.png";
import logo6 from "../../utils/logos/logo6.png";

const convertUnixToDate = (unixTime) => new Date(unixTime * 1000);

// Styled filter container div to align right and apply Poppins font
const FilterContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "2rem",
  padding: "1rem 2rem",
  fontFamily: "Poppins, sans-serif",
});

// Button styles to keep it minimal and sleek
const FilterButton = styled(Button)(({ active }) => ({
  color: active ? "#fff" : "#000",
  backgroundColor: active ? "#1976d2" : "#f5f5f5",
  textTransform: "none",
  border: "none",
  boxShadow: "none",
  fontFamily: "Poppins, sans-serif",
  "&:hover": {
    backgroundColor: active ? "#115293" : "#e0e0e0",
  },
}));

const Analytics = () => {
  const [loading, setLoading] = useState(true); // Loading state
  const [tasksData, setTasksData] = useState([]); // State to hold the tasks data
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [timeFilter, setTimeFilter] = useState("24h"); // Default filter to "Last 24 Hours"
  const { theme, setTheme } = useTheme();

  // Fetch API data on component mount
  useEffect(() => {
    const setCompanyData = async () => {
      try {
        const companyName = window.sessionStorage.getItem("companyName");
        const response = await axios.post(
          // "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompletedtasks",
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/zzz",
          { company: companyName }
        );

        const data = response.data;
        console.log(data);

        // Ensure tasksData is always an array
        setTasksData(data.completedTasksData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching the tasks data", error);
        setTasksData([]); // Set tasksData to an empty array on error
        setLoading(false);
      }
    };

    // Call the function to fetch data on component mount
    setCompanyData();
  }, []);
  // Empty dependency array ensures this runs only once when the component mounts
  useEffect(() => {
    const now = new Date();
    let filteredTasksList = [];

    switch (timeFilter) {
      case "24h":
        filteredTasksList = tasksData.filter((task) => {
          const taskEndTime = task.task_log?.task_end_time;
          return (
            taskEndTime &&
            now - convertUnixToDate(taskEndTime) <= 24 * 60 * 60 * 1000
          );
        });
        break;
      case "1w":
        filteredTasksList = tasksData.filter((task) => {
          const taskEndTime = task.task_log?.task_end_time;
          return (
            taskEndTime &&
            now - convertUnixToDate(taskEndTime) <= 7 * 24 * 60 * 60 * 1000
          );
        });
        break;
      case "1m":
        filteredTasksList = tasksData.filter((task) => {
          const taskEndTime = task.task_log?.task_end_time;
          return (
            taskEndTime &&
            now - convertUnixToDate(taskEndTime) <= 30 * 24 * 60 * 60 * 1000
          );
        });
        break;
      case "6m":
        filteredTasksList = tasksData.filter((task) => {
          const taskEndTime = task.task_log?.task_end_time;
          return (
            taskEndTime &&
            now - convertUnixToDate(taskEndTime) <= 6 * 30 * 24 * 60 * 60 * 1000
          );
        });
        break;
      case "all":
      default:
        filteredTasksList = tasksData;
    }

    setFilteredTasks(filteredTasksList);
  }, [tasksData, timeFilter]); // Re-run when tasksData or timeFilter changes

  // Handle filter button click
  const handleFilterClick = (filter) => {
    setTimeFilter(filter);
  };

  const cardData = useMemo(() => {
    return [
      {
        title: "Active Bots",
        subtitle: "Number of unique active bots",
        value: new Set(tasksData.map((task) => task.task_log.assigned_bot))
          .size,
        icon: (
          <img
            src={logo1}
            alt="Active Bots"
            style={{ width: 20, height: 20 }}
          />
        ),
        iconColor: "#9fd575",
      },
      {
        title: "Paths",
        subtitle: "Total paths completed",
        value: new Set(
          tasksData.map((task) => task.task_detail?.task_station || [])
        ).size,
        icon: <img src={logo2} alt="Paths" style={{ width: 20, height: 20 }} />,
        iconColor: "#d096f4",
      },
      {
        title: "People Helped",
        subtitle: "Tasks involving people",
        value: new Set(tasksData.map((task) => task.task_log.created_user))
          .size,
        icon: (
          <img
            src={logo3}
            alt="People Helped"
            style={{ width: 20, height: 20 }}
          />
        ),
        iconColor: "#f2c657",
      },
      {
        title: "Forklifts",
        subtitle: "Tasks with forklifts",
        value: tasksData.filter((task) => task.equipment === "forklift").length,
        icon: (
          <img src={logo4} alt="Forklifts" style={{ width: 20, height: 20 }} />
        ),
        iconColor: "#f18f70",
      },
      {
        title: "Stations",
        subtitle: "Total stations",
        value: new Set(
          tasksData.flatMap((task) => task.task_detail?.task_station || [])
        ).size,
        icon: (
          <img src={logo5} alt="Stations" style={{ width: 20, height: 20 }} />
        ),
        iconColor: "#3dcfcf",
      },
      {
        title: "Zones",
        subtitle: "Tasks in zones",
        value: tasksData.filter((task) => task.zone_id).length,
        icon: <img src={logo6} alt="Zones" style={{ width: 20, height: 20 }} />,
        iconColor: "#f3b7de",
      },
    ];
  }, [tasksData]);

  // If the data is still loading, show a loader
  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress />
        <p>Loading analytics data...</p>
      </div>
    );
  }

  // If tasksData is still not available, return null (optional safeguard)
  if (!tasksData) {
    return null;
  }

  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      <div
        style={{
          display: "flex",
          padding: "0rem",
          backgroundColor: theme === "light" ? "#f5f5f5" : "#a3cad9",
          
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <div
            style={{
              backgroundColor: theme === "light" ? "#DBF1FAF2" : "#0B2D3B",
              color: theme === "light" ? "#0B2D3B" : "#fff",
              padding: "2rem 2rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between", // Add this to push items to the edges
              alignItems: "center", // Vertically center the items
            }}
          >
            <span style={{ fontWeight: 600, fontSize: "1.5rem" }}>
              Analytics
            </span>
            <button
              className="text-black font-semibold bg-white px-2 py-2 rounded-lg hover:bg-[grey] hover:text-white hover:scale-105 transition-colors duration-300"
              onClick={() => (window.location.href = "/home")}
            >
              {`←  Back to Home`}
            </button>
          </div>

          <div style={{ padding: "0rem 2rem"}}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "1rem",
                marginTop: "1rem",
                marginBottom: "0.5rem",
              }}
            >
              Summary
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: "24px" }}>
              {cardData.map((card, index) => (
                <div
                  key={index}
                  style={{
                    flex: "1 1 calc(33.333% - 24px)", // 3 columns for larger screens (md/lg)
                    maxWidth: "calc(33.333% - 24px)", // Enforce max width per card
                    minWidth: "300px", // Minimum width for smaller screens
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SummaryCard
                    title={card.title}
                    // subtitle={card.subtitle}
                    value={card.value}
                    icon={card.icon}
                    iconColor={card.iconColor}
                  />
                </div>
              ))}
            </div>
          </div>

          <FilterContainer>
            <ButtonGroup variant="contained">
              <FilterButton
                onClick={() => handleFilterClick("all")}
                active={timeFilter === "all"}
              >
                All Time
              </FilterButton>
              <FilterButton
                onClick={() => handleFilterClick("24h")}
                active={timeFilter === "24h"}
              >
                Last 24 Hours
              </FilterButton>
              <FilterButton
                onClick={() => handleFilterClick("1w")}
                active={timeFilter === "1w"}
              >
                Last 1 Week
              </FilterButton>
              <FilterButton
                onClick={() => handleFilterClick("1m")}
                active={timeFilter === "1m"}
              >
                Last 1 Month
              </FilterButton>
              <FilterButton
                onClick={() => handleFilterClick("6m")}
                active={timeFilter === "6m"}
              >
                Last 6 Months
              </FilterButton>
            </ButtonGroup>
          </FilterContainer>

          <div style={{...appContainerStyle, backgroundColor: theme === "light" ? "#f5f5f5" : "#a3cad9"}}>
            <div style={cardStyle}>
              <TaskSummary filteredTasks={filteredTasks} />
            </div>
            <div style={cardStyle}>
              <TaskByStation filteredTasks={filteredTasks} />
            </div>
          </div>

          {/* <div style={{ padding: "1rem 2rem" }}>
          <div style={{ fontWeight: 600, fontSize: "1rem", marginTop: "1rem" }}>
            Filtered Tasks Count: {filteredTasks.length}
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

const appContainerStyle = {
  display: "flex",
  gap: "16px",
  padding: "16px",
  backgroundColor: "#fff",
};

const cardStyle = {
  flex: 1,
  background: "white",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  padding: "24px",
};

export default Analytics;
