import React, { useRef, useEffect, useState } from "react";
import {
  usePathDraw,
  useAIPathDraw,
  useRecordPath,
  useZone,
} from "../Context/Context.js";
import { addIntermediatePoints } from "../helper_functions/addIntermediatePoints.js";
import {
  isPointInPolygon,
  polySort,
  squaredPolar,
} from "../helper_functions/Zone.js";
import axios from "axios";
const Canvas = ({
  tags,
  cameraZoom,
  opacity,
  setCameraZoom,
  joystickPosition,
}) => {
  window.sessionStorage.setItem("tags", JSON.stringify(tags));
  const { ToRecord } = usePathDraw();
  const { AIpath, setAIpathArray } = useAIPathDraw();
  const {
    pathRecord,
    setPathRecord,
    showStation,
    setShowStation,
    showBots,
    setShowBots,
    showMaterials,
    showZone,
    setShowZone,
    setshowMaterials,
    showPeople,
    setshowPeople,
    showCartParking,
    setShowCartParking,
    selectedCartParking,
    setSelectedCartParking,
    showParking,
    setShowParking,
    selectedParking,
    setSelectedParking,
    showpath,
    setShowpath,
    hoveredPath,
    setHoveredPath,
    canvasWidth,
    setCanvasWidth,
    cameraOffset,
    setCameraOffset,
    selectedBot,
    setSelectedBot,
    selectedPath,
    setSelectedPath,
    selectedZone,
    setSelectedZone,
    selectedStation,
    setSelectedStation,
    selectedPeople,
    setSelectedPeople,
    stationRecord,
    setStationRecord,
    stationMarkedCoordinate,
    setStationMarkedCoordinate,
    AIPathSensitivity,
    setAIPathSensitivity,
    tracePath,
    setTracePath,
    tagName,
    settagName,
    botTraceArray,
    setBotTraceArray,
    jitterbotCoordinates,
    setjitterbotCoordinates,
    parkingSpaceCoordinates,
    setParkingSpaceCoordinates,
    startParkingSpaceRecord,
    setStartParkingSpaceRecord,
    cartParkingSpaceCoordinates,
    setCartParkingSpaceCoordinates,
    startCartParkingSpaceRecord,
    setStartCartParkingSpaceRecord,
  } = useRecordPath();
  const [rerender, setRerender] = useState(false);
  const { ZonePointArray, setZonePointArray, CreateZone } = useZone();
  const [startRecord, setStartRecord] = useState(false);
  const [botMapping, setBotMapping] = useState({});
  const [materialMapping, setmaterialMapping] = useState({});
  const [PeopleMapping, setPeopleMapping] = useState({});
  const selectedTag = tags[tagName];
  const canvasRef = useRef(null);
  useEffect(() => {
    const stationData = JSON.parse(
      window.sessionStorage.getItem("stationData")
    );
    const pathData = JSON.parse(window.sessionStorage.getItem("pathData"));
    const zoneData = JSON.parse(window.sessionStorage.getItem("zoneData"));

    setSelectedStation(stationData);
    setSelectedPath(pathData.paths);
  }, []);

  const companyName = window.sessionStorage.getItem("companyName");
  const updateOffset = () => {
    setCameraOffset((prevOffset) => ({
      x: prevOffset.x - joystickPosition.x / 10,
      y: prevOffset.y + joystickPosition.y / 10,
    }));
  };
  useEffect(() => {
    if (ToRecord) updateOffset();
    let animationFrameId;

    const animate = () => {
      if (ToRecord) {
        updateOffset();
      }
      animationFrameId = requestAnimationFrame(animate);
    };
    animate();
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [joystickPosition, ToRecord]);

  useEffect(() => {
    const fetchMappings = async () => {
      try {
        const botResponse = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getBotMapping",
          {
            companyName: window.sessionStorage.getItem("companyName"),
          }
        );
        setBotMapping(botResponse.data);
        window.sessionStorage.setItem(
          "botMapping",
          JSON.stringify(botResponse.data)
        );

        const materialResponse = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getMaterialsMapping",
          {
            companyName: window.sessionStorage.getItem("companyName"),
          }
        );
        setmaterialMapping(materialResponse.data);

        const peopleResponse = await axios.post(
          "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getPeopleMapping",
          {
            companyName: window.sessionStorage.getItem("companyName"),
          }
        );
        setPeopleMapping(peopleResponse.data);
        window.sessionStorage.setItem(
          "PeopleMapping",
          JSON.stringify(peopleResponse.data)
        );
      } catch (error) {
        console.error("Error fetching mappings:", error);
      }
    };
    fetchMappings();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    setCanvasWidth(canvas.width);
    const ctx = canvas.getContext("2d");
    const companymap = window.sessionStorage.getItem("companyName");
    const MAX_ZOOM = 5;
    const MIN_ZOOM = 0.1;
    const SCROLL_SENSITIVITY = 0.000001;
    const DRAG_SENSITIVITY = 0.6;

    var xFlipped = 1;
    var yFlipped = -1;
    var scaleFactor =
        companymap == "Drobot 1" ? 24.1304520095363 : 85.27279871798186,
      originX =
        companymap == "Drobot 1"
          ? 23462.84145899498 / scaleFactor
          : 26619.109239724097 / scaleFactor,
      originY =
        companymap == "Drobot 1"
          ? yFlipped * (-55755.01018319527 / scaleFactor)
          : yFlipped * (-98757.17160965082 / scaleFactor);
    var real_distance_waypoints = 50; //in cm
    var record_distance_factor = real_distance_waypoints / (scaleFactor * 10);
    var windowinnerWidth = window.innerWidth;
    var windowinnerHeight = window.innerHeight;
    let isDragging = false;
    let dragStart = { x: 0, y: 0 };
    let initialPinchDistance = null;
    let lastZoom = cameraZoom;
    const img = new Image();
    canvas.style.backgroundColor = "white";

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    function removeClosePoints(
      xVals,
      yVals,
      thresholdDistance = record_distance_factor / 10
    ) {
      const newPoints = [{ x: xVals[0], y: yVals[0] }];
      const lastPoints = {
        x: xVals[xVals.length - 1],
        y: yVals[yVals.length - 1],
      };

      for (let i = 1; i < xVals.length; i++) {
        const distance = Math.sqrt(
          (xVals[i] - newPoints[newPoints.length - 1].x) ** 2 +
            (yVals[i] - newPoints[newPoints.length - 1].y) ** 2
        );

        if (distance >= thresholdDistance) {
          newPoints.push({ x: xVals[i], y: yVals[i] });
        }
      }
      newPoints.push(lastPoints);

      return newPoints;
    }

    function smoothPath(points, windowSize) {
      const smoothedPoints = [{ x: points[0].x, y: points[0].y }];

      for (let i = 0; i < points.length; i++) {
        let sumX = 0,
          sumY = 0;

        for (let j = Math.max(0, i - windowSize + 1); j <= i; j++) {
          sumX += points[j].x;
          sumY += points[j].y;
        }

        const averageX = sumX / Math.min(i + 1, windowSize);
        const averageY = sumY / Math.min(i + 1, windowSize);

        smoothedPoints.push({ x: averageX, y: averageY });
      }

      return smoothedPoints;
    }

    function alignPathToAxes(x, y, angleThreshold) {
      const alignedX = [x[0]];
      const alignedY = [y[0]];

      for (let i = 1; i < x.length; i++) {
        const angleX = Math.abs(
          (Math.atan2(y[i] - y[i - 1], x[i] - x[i - 1]) * 180) / Math.PI
        );
        const angleY = Math.abs(
          (Math.atan2(y[i] - y[i - 1], x[i] - x[i - 1]) * 180) / Math.PI - 90
        );

        if (
          (angleX <= angleThreshold || angleX >= 180 - angleThreshold) &&
          (angleY <= 90 + angleThreshold || angleY >= 270 - angleThreshold)
        ) {
          alignedX.push(x[i]);
          alignedY.push(alignedY[alignedY.length - 1]);
        } else if (
          (angleY <= angleThreshold || angleY >= 180 - angleThreshold) &&
          (angleX <= 90 + angleThreshold || angleX >= 270 - angleThreshold)
        ) {
          alignedX.push(alignedX[alignedX.length - 1]);
          alignedY.push(y[i]);
        } else {
          alignedX.push(x[i]);
          alignedY.push(y[i]);
        }
      }

      return { pose: { x: alignedX, y: alignedY } };
    }

    const draw = async () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      ctx.translate(windowinnerWidth / 2, windowinnerHeight / 2);
      ctx.scale(cameraZoom, cameraZoom);
      ctx.translate(
        -windowinnerWidth / 2 + cameraOffset.x,
        -windowinnerHeight / 2 + cameraOffset.y
      );
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
      img.src =
        companymap === "Drobot 1" ? "Drobot.jpg" : "AFL Accessories.jpg";
      ctx.save();
      ctx.globalAlpha = opacity;
      ctx.drawImage(
        img,
        -img.naturalWidth / 2,
        -img.naturalHeight / 2,
        img.naturalWidth,
        img.naturalHeight
      );
      ctx.restore();

      const selectedBotsArray = Array.isArray(selectedBot) ? selectedBot : [];
      selectedBotsArray.forEach((botName) => {
        const tagCoordinates = tags;
        const tagId = Object.keys(botMapping).find(
          (id) => botMapping[id] === botName
        );

        if (tagId) {
          const tag = tagCoordinates[tagId];
          const botNameFromMapping = botMapping[tagId];

          if (tag) {
            const x =
              (xFlipped * tag.botCoordinateX) / scaleFactor -
              img.naturalWidth / 2 +
              originX;
            const y =
              (yFlipped * tag.botCoordinateY) / scaleFactor -
              img.naturalHeight / 2 +
              originY;

            ctx.fillStyle = "green";
            drawCirc(x, y);
            drawstrokeCirc(x, y);
            ctx.fillStyle = "black";
            drawText(botNameFromMapping, x - 20, y + 22, 12, "courier");
          }
        }
      });

      if (showMaterials) {
        const tagCoordinates = tags;
        const tagIds = Object.keys(tags);
        let numberOfTags = tagIds.length;
        while (numberOfTags !== 0) {
          numberOfTags--;
          const tagId = tagIds[numberOfTags];
          const tag = tagCoordinates[tagId];
          const materialname = materialMapping[tagId];
          const x =
            (xFlipped * tag.botCoordinateX) / scaleFactor -
            img.naturalWidth / 2 +
            originX;
          const y =
            (yFlipped * tag.botCoordinateY) / scaleFactor -
            img.naturalHeight / 2 +
            originY;
          if (materialname) {
            ctx.fillStyle = "red";
            drawCirc(x, y);
            drawstrokeCirc(x, y);
            ctx.fillStyle = "black";
            drawText(materialname, x - 20, y + 22, 12, "courier");
          }
        }
      }
      if (startParkingSpaceRecord) {
        if (parkingSpaceCoordinates.x != null) {
          const radius = 400 / scaleFactor;
          const arrowLength = 1500 / scaleFactor;

          const arrowAngle = parkingSpaceCoordinates.yaw
            ? (parkingSpaceCoordinates.yaw * Math.PI) / 180
            : 0;

          const arrowX =
            parkingSpaceCoordinates.x + arrowLength * Math.cos(arrowAngle);
          const arrowY =
            parkingSpaceCoordinates.y + arrowLength * Math.sin(arrowAngle);

          ctx.beginPath();
          ctx.moveTo(parkingSpaceCoordinates.x, parkingSpaceCoordinates.y);
          ctx.lineTo(arrowX, arrowY);
          ctx.strokeStyle = "blue";
          ctx.lineWidth = 2;
          ctx.stroke();
          ctx.closePath();

          ctx.beginPath();
          ctx.arc(
            parkingSpaceCoordinates.x,
            parkingSpaceCoordinates.y,
            radius,
            0,
            2 * Math.PI
          );
          ctx.strokeStyle = "blue"; // Set the color for the circle
          ctx.lineWidth = 4; // Set the line width for the circle
          ctx.stroke();
          ctx.closePath();

          // Modified drawArrow function
          const drawArrow = (p1, p2) => {
            const arrowSize = 10;
            const dx = p2.x - p1.x;
            const dy = p2.y - p1.y;
            const angle = Math.atan2(dy, dx);

            const arrowPoint1 = {
              x: p2.x - arrowSize * Math.cos(angle - Math.PI / 6),
              y: p2.y - arrowSize * Math.sin(angle - Math.PI / 6),
            };

            const arrowPoint2 = {
              x: p2.x - arrowSize * Math.cos(angle + Math.PI / 6),
              y: p2.y - arrowSize * Math.sin(angle + Math.PI / 6),
            };

            ctx.fillStyle = "blue"; // Set arrow color to blue
            ctx.beginPath();
            ctx.moveTo(p2.x, p2.y); // Arrow tip
            ctx.lineTo(arrowPoint1.x, arrowPoint1.y); // Left side of arrowhead
            ctx.lineTo(arrowPoint2.x, arrowPoint2.y); // Right side of arrowhead
            ctx.closePath();
            ctx.fill();
          };

          // Draw an arrow pointing from parkingSpaceCoordinates to the calculated arrow tip
          drawArrow(
            { x: parkingSpaceCoordinates.x, y: parkingSpaceCoordinates.y },
            { x: arrowX, y: arrowY }
          );
        }
      }

      if (startCartParkingSpaceRecord) {
        if (cartParkingSpaceCoordinates.P.center.x != null) {
          const rectWidth = 1500 / scaleFactor;
          const rectHeight = 1000 / scaleFactor;
          const arrowLength = 1750 / scaleFactor;
          const pointOffset = 5000 / scaleFactor;
          const drawAllElements = (center, width, height, angle) => {
            ctx.save();

            // Translate and rotate to the rectangle center
            ctx.translate(center.x, center.y);
            ctx.rotate(angle);

            // Draw the rectangle
            ctx.beginPath();
            ctx.rect(-width / 2, -height / 2, width, height);
            ctx.strokeStyle = "blue";
            ctx.lineWidth = 2;
            ctx.stroke();
            ctx.closePath();

            // Draw the arrow
            const arrowX = arrowLength * Math.cos(0); // Arrow is drawn from the center
            const arrowY = arrowLength * Math.sin(0);
            ctx.beginPath();
            ctx.moveTo(0, 0); // Start from the rectangle's center
            ctx.lineTo(arrowX, arrowY); // Arrow's endpoint
            ctx.strokeStyle = "red";
            ctx.lineWidth = 2;
            ctx.stroke();
            ctx.closePath();

            // Restore the context for independent point calculations
            ctx.restore();

            // Calculate P' (1m along the vector)
            const P_prime = {
              x: center.x + arrowLength * Math.cos(angle),
              y: center.y + arrowLength * Math.sin(angle),
            };
            // Px and Py (points perpendicular to the vector)
            const Px = {
              x: P_prime.x + (pointOffset / 2) * Math.cos(angle + Math.PI / 2),
              y: P_prime.y + (pointOffset / 2) * Math.sin(angle + Math.PI / 2),
            };

            // Py: 2.5 meters below P' (perpendicular to the vector)
            const Py = {
              x: P_prime.x + (pointOffset / 2) * Math.cos(angle - Math.PI / 2),
              y: P_prime.y + (pointOffset / 2) * Math.sin(angle - Math.PI / 2),
            };

            // Draw P'
            ctx.beginPath();
            ctx.arc(P_prime.x, P_prime.y, 5, 0, 2 * Math.PI); // Circle for P'
            ctx.fillStyle = "green";
            ctx.fill();
            ctx.closePath();

            // Draw Px and Py
            ctx.beginPath();
            ctx.arc(Px.x, Px.y, 5, 0, 2 * Math.PI); // Circle for Px
            ctx.arc(Py.x, Py.y, 5, 0, 2 * Math.PI); // Circle for Py
            ctx.fillStyle = "purple";
            ctx.fill();
            ctx.closePath();
          };
          const degreesToRadians = (degrees) => degrees * (Math.PI / 180);
          drawAllElements(
            {
              x: cartParkingSpaceCoordinates.P.center.x,
              y: cartParkingSpaceCoordinates.P.center.y,
            },
            rectWidth,
            rectHeight,
            degreesToRadians(cartParkingSpaceCoordinates.yaw)
          );
        }
      }

      const selectedPeopleArray = Array.isArray(selectedPeople)
        ? selectedPeople
        : [];
      selectedPeopleArray.forEach((personName) => {
        const tagCoordinates = tags;
        const tagId = Object.keys(PeopleMapping).find(
          (id) => PeopleMapping[id] === personName
        );

        if (tagId) {
          const tag = tagCoordinates[tagId];
          const Peoplename = PeopleMapping[tagId];

          if (tag) {
            const x =
              (xFlipped * tag.botCoordinateX) / scaleFactor -
              img.naturalWidth / 2 +
              originX;
            const y =
              (yFlipped * tag.botCoordinateY) / scaleFactor -
              img.naturalHeight / 2 +
              originY;

            ctx.fillStyle = "yellow";
            drawCirc(x, y);
            drawstrokeCirc(x, y);
            ctx.fillStyle = "black";
            drawText(Peoplename, x - 20, y + 22, 12, "courier");
          }
        }
      });
      selectedZone.forEach((zone) => {
        if (
          zone &&
          Array.isArray(zone.x) &&
          Array.isArray(zone.y) &&
          zone.x.length > 0 &&
          zone.y.length > 0
        ) {
          const xList = zone.x;
          const yList = zone.y;
          ctx.beginPath();
          ctx.moveTo(
            xFlipped *
              ((xList[0] * 10) / scaleFactor -
                (xFlipped * img.naturalWidth) / 2 +
                xFlipped * originX),
            yFlipped *
              ((yList[0] * 10) / scaleFactor -
                (yFlipped * img.naturalHeight) / 2 +
                yFlipped * originY)
          );
          ctx.lineWidth = 4;

          for (let i = 0; i < xList.length; i++) {
            ctx.lineTo(
              xFlipped *
                ((xList[i] * 10) / scaleFactor -
                  (xFlipped * img.naturalWidth) / 2 +
                  xFlipped * originX),
              yFlipped *
                ((yList[i] * 10) / scaleFactor -
                  (yFlipped * img.naturalHeight) / 2 +
                  yFlipped * originY)
            );
          }
          ctx.closePath();
          ctx.fillStyle = "rgba(109, 109, 146, 0.61)";
          ctx.fill();
          ctx.strokeStyle = "grey";
          ctx.stroke();
          ctx.closePath();
        }
      });
      if (stationMarkedCoordinate.x != null) {
        ctx.fillStyle = "black";
        drawCirc(stationMarkedCoordinate.x, stationMarkedCoordinate.y);
      }

      if (Array.isArray(selectedPath)) {
        selectedPath.forEach((path) => {
          if (
            path &&
            path.pose &&
            Array.isArray(path.pose.x) &&
            Array.isArray(path.pose.y)
          ) {
            const pathCoordinate = path.pose;
            const xList = pathCoordinate.x;
            const yList = pathCoordinate.y;

            ctx.beginPath();
            ctx.lineWidth = 3;

            if (hoveredPath && hoveredPath.pathname === path.pathname) {
              ctx.strokeStyle = "red"; // Highlight color for hovered path
            } else {
              ctx.strokeStyle = "black"; // Default color for other paths
            }

            const pathPoints = [];

            for (let i = 0; i < xList.length; i++) {
              const x =
                xFlipped *
                ((xList[i] * 10) / scaleFactor -
                  (xFlipped * img.naturalWidth) / 2 +
                  xFlipped * originX);
              const y =
                yFlipped *
                ((yList[i] * 10) / scaleFactor -
                  (yFlipped * img.naturalHeight) / 2 +
                  yFlipped * originY);
              ctx.lineTo(x, y);
              pathPoints.push({ x, y });
            }

            ctx.stroke();
            ctx.closePath();

            if (pathPoints.length > 1) {
              drawArrowOnPath(ctx, pathPoints, path.Properties.directionality);
            }

            // Draw green dots at both edge coordinates

            if (path.Properties.directionality == "directed") {
              ctx.fillStyle = "red";
              drawCircPath(
                xFlipped *
                  ((xList[0] * 10) / scaleFactor -
                    (xFlipped * img.naturalWidth) / 2 +
                    xFlipped * originX),
                yFlipped *
                  ((yList[0] * 10) / scaleFactor -
                    (yFlipped * img.naturalHeight) / 2 +
                    yFlipped * originY)
              );
            }
          } else {
            console.warn("Invalid path or pose data:", path);
          }
        });
      } else {
        console.error("selectedPath is not an array or is undefined.");
      }
      if (Array.isArray(selectedCartParking)) {
        selectedCartParking.forEach((station) => {
          if (station.cart_parking_empty)
            drawCartParkingSpace(station.cart_parking_empty, "empty");
          if (station.cart_parking_full)
            drawCartParkingSpace(station.cart_parking_full, "full");
        });
      }
      if (Array.isArray(selectedParking)) {
        selectedParking.forEach((station) => {
          if (station.bot_parking != null) {
            // console.log(station.bot_parking);

            drawParkingSpace(
              (xFlipped * station.bot_parking.x * 10) / scaleFactor -
                img.naturalWidth / 2 +
                originX -
                20,
              (yFlipped * station.bot_parking.y * 10) / scaleFactor -
                img.naturalHeight / 2 +
                originY -
                10,
              station.bot_parking.yaw
            );
          }
        });
      }
      if (Array.isArray(selectedStation)) {
        selectedStation.forEach((station) => {
          if (station != null) {
            const stationCoordinate = station.pose;
            ctx.fillStyle = "black";
            ctx.beginPath();
            ctx.lineWidth = 3;

            drawCirc(
              (xFlipped * stationCoordinate.x * 10) / scaleFactor -
                img.naturalWidth / 2 +
                originX,
              (yFlipped * stationCoordinate.y * 10) / scaleFactor -
                img.naturalHeight / 2 +
                originY
            );
            ctx.fillStyle = "black";
            drawText(
              station.stationName,
              (xFlipped * stationCoordinate.x * 10) / scaleFactor -
                img.naturalWidth / 2 +
                originX -
                20,
              (yFlipped * stationCoordinate.y * 10) / scaleFactor -
                img.naturalHeight / 2 +
                originY -
                10,
              12,
              "courier"
            );
          }
        });
      }
      if (ToRecord) {
        if (pathRecord.x.length != 0) {
          drawplotPath();
          var xList = pathRecord.x;
          var yList = pathRecord.y;

          if (AIpath) {
            let points1 = removeClosePoints(xList, yList);

            let points = addIntermediatePoints(points1, 10);
            let lastPointsX = pathRecord.x[pathRecord.x.length - 1];
            let lastPointsY = pathRecord.y[pathRecord.y.length - 1];

            xList = [];
            yList = [];
            var window_size = AIPathSensitivity;

            let smoothedPoints = smoothPath(points, window_size);
            xList.push(pathRecord.x[0]);
            yList.push(pathRecord.y[0]);
            for (let i = 0; i < smoothedPoints.length; i++) {
              xList.push(smoothedPoints[i].x);
              yList.push(smoothedPoints[i].y);
            }
            var straightig_threshold = 10;
            var straighten_points = alignPathToAxes(
              xList,
              yList,
              straightig_threshold
            );
            xList = straighten_points.pose.x;
            yList = straighten_points.pose.y;

            points = removeClosePoints(xList, yList);

            // Prepare the final smoothed path
            xList = [];
            yList = [];
            var window_size = AIPathSensitivity;
            smoothedPoints = smoothPath(points, window_size);
            for (let i = 0; i < smoothedPoints.length; i++) {
              xList.push(smoothedPoints[i].x);
              yList.push(smoothedPoints[i].y);
            }

            var straightig_threshold = 10;
            var straighten_points = alignPathToAxes(
              xList,
              yList,
              straightig_threshold
            );
            xList = straighten_points.pose.x;
            yList = straighten_points.pose.y;

            points = removeClosePoints(xList, yList);

            xList = [];
            yList = [];

            smoothedPoints = await smoothPath(points, window_size);
            for (let i = 0; i < smoothedPoints.length; i++) {
              xList.push(smoothedPoints[i].x);
              yList.push(smoothedPoints[i].y);
            }
            xList.push(lastPointsX);
            yList.push(lastPointsY);

            setAIpathArray({
              x: xList,
              y: yList,
            });

            ctx.beginPath();
            ctx.moveTo(xList[0], yList[0]);
            ctx.lineWidth = 4;

            // Draw the path using the smoothed points
            for (let i = 0; i < xList.length; i++) {
              ctx.lineTo(xList[i], yList[i], xList[i + 1], yList[i + 1]);
            }
            ctx.strokeStyle = "lime";
            ctx.stroke();
            ctx.closePath();
          }
        }
      }
      if (tracePath) {
        drawBotPath();
        drawBotJitterPath();
      }

      if (CreateZone && ZonePointArray.x.length >= 3) {
        ctx.beginPath();
        ctx.moveTo(ZonePointArray.x[0], ZonePointArray.y[0]);
        ctx.lineWidth = 4;

        for (let i = 0; i < ZonePointArray.x.length; i++) {
          ctx.lineTo(
            ZonePointArray.x[i],
            ZonePointArray.y[i],
            ZonePointArray.x[i + 1],
            ZonePointArray.y[i + 1]
          );
        }
        ctx.strokeStyle = "lime";
        ctx.stroke();
        ctx.closePath();
      }
      requestAnimationFrame(draw);
    };
    const handleZoneClick = (x, y) => {
      const polygon = ZonePointArray.x.map((xVal, index) => [
        xVal,
        ZonePointArray.y[index],
      ]);
      if (isPointInPolygon(x, y, polygon)) {
        return; // Do nothing if the point is inside the polygon
      }

      let match = ZonePointArray.x.findIndex(
        (xVal, index) =>
          Math.abs(xVal - x) + Math.abs(ZonePointArray.y[index] - y) <= 6
      );
      let newPoints = {
        x: [],
        y: [],
      };
      if (match < 0) {
        newPoints = {
          x: [...ZonePointArray.x, x],
          y: [...ZonePointArray.y, y],
        };
        setZonePointArray(newPoints);
      } else {
        newPoints = {
          x: ZonePointArray.x.slice(),
          y: ZonePointArray.y.slice(),
        };
        newPoints.x.splice(match, 1);
        newPoints.y.splice(match, 1);
        setZonePointArray(newPoints);
      }

      polySort(newPoints);
      if (newPoints.x.length >= 3) {
        newPoints.x.push(newPoints.x[0]);
        newPoints.y.push(newPoints.y[0]);
      }
    };
    function drawCirc(x, y) {
      ctx.beginPath();
      ctx.arc(x, y, 6, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();
    }
    function drawArrowOnPath(ctx, pathPoints, directionality, arrowSize = 12) {
      if (pathPoints.length < 2) {
        console.error("Path must have at least two points to draw an arrow.");
        return;
      }

      const drawArrow = (p1, p2) => {
        const dx = p2.x - p1.x;
        const dy = p2.y - p1.y;
        const angle = Math.atan2(dy, dx);

        const arrowPoint1 = {
          x: p2.x - arrowSize * Math.cos(angle - Math.PI / 6),
          y: p2.y - arrowSize * Math.sin(angle - Math.PI / 6),
        };

        const arrowPoint2 = {
          x: p2.x - arrowSize * Math.cos(angle + Math.PI / 6),
          y: p2.y - arrowSize * Math.sin(angle + Math.PI / 6),
        };

        ctx.fillStyle = "black"; // Set arrow color to blue
        ctx.beginPath();
        ctx.moveTo(p2.x, p2.y); // Arrow tip
        ctx.lineTo(arrowPoint1.x, arrowPoint1.y); // Left side of arrowhead
        ctx.lineTo(arrowPoint2.x, arrowPoint2.y); // Right side of arrowhead
        ctx.closePath();
        ctx.fill();
      };

      const drawRhombus = (ctx, centerX, centerY, width, height) => {
        const halfWidth = width / 2;
        const halfHeight = height / 2;

        ctx.beginPath();
        ctx.moveTo(centerX, centerY - halfHeight);
        ctx.lineTo(centerX + halfWidth, centerY); // Right vertex
        ctx.lineTo(centerX, centerY + halfHeight); // Bottom vertex
        ctx.lineTo(centerX - halfWidth, centerY); // Left vertex
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
      };

      if (directionality === "directed") {
        drawArrow(
          pathPoints[pathPoints.length - 2],
          pathPoints[pathPoints.length - 1]
        );
      } else {
        drawRhombus(
          ctx,
          pathPoints[pathPoints.length - 1].x,
          pathPoints[pathPoints.length - 1].y,
          (2 * arrowSize) / 3,
          (2 * arrowSize) / 3
        );
        drawRhombus(
          ctx,
          pathPoints[0].x,
          pathPoints[0].y,
          (2 * arrowSize) / 3,
          (2 * arrowSize) / 3
        );
      }

      for (let i = 4; i < pathPoints.length - 5; i += 10) {
        if (directionality === "directed") {
          drawArrow(pathPoints[i - 1], pathPoints[i]);
        } else {
          drawRhombus(
            ctx,
            pathPoints[i].x,
            pathPoints[i].y,
            (2 * arrowSize) / 3,
            (2 * arrowSize) / 3
          );
        }
      }
    }

    function drawCircPath(x, y) {
      ctx.beginPath();
      ctx.arc(x, y, 3, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();
    }

    function drawplotPath() {
      ctx.strokeStyle = "black";
      const xList = pathRecord.x;
      const yList = pathRecord.y;
      ctx.beginPath();
      ctx.moveTo(xList[0], yList[0]);
      ctx.lineWidth = 3;

      for (let i = 1; i < xList.length; i++) {
        ctx.lineTo(xList[i], yList[i]);
      }
      ctx.stroke();
      ctx.closePath();
    }

    function drawstrokeCirc(x, y) {
      ctx.beginPath();
      ctx.globalAlpha = 0.3;
      ctx.arc(x, y, 14, 0, 2 * Math.PI);
      ctx.fill();
      ctx.globalAlpha = 1;
      ctx.closePath();
    }

    function drawText(text, x, y, size, font) {
      ctx.font = `${size}px ${font}`;
      ctx.fillText(text, x, y);
    }

    function recordParkingSpace(x1, y1) {
      let xin = x1;
      let yin = y1;
      console.log(xin, yin, x1, y1);

      if (
        parkingSpaceCoordinates.x != null &&
        parkingSpaceCoordinates.y != null
      ) {
        const calculateAngle = (x1, y1, x2, y2) => {
          const dx = x2 - x1;
          const dy = y2 - y1;
          const angle = Math.atan2(dy, dx) * (180 / Math.PI);
          console.log(angle);
          return angle > 180 ? angle - 360 : angle;
        };

        const angle = calculateAngle(
          parkingSpaceCoordinates.x,
          parkingSpaceCoordinates.y,
          xin,
          yin
        );

        parkingSpaceCoordinates.yaw = angle;
        return;
      }
      setParkingSpaceCoordinates({
        ...parkingSpaceCoordinates,
        x: xin,
        y: yin,
        yaw: null,
      });

      setRerender(!rerender);
    }

    function recordCartParkingSpace(x1, y1) {
      const radiansToDegrees = (radians) => radians * (180 / Math.PI);
      const degreesToRadians = (degrees) => degrees * (Math.PI / 180);

      if (cartParkingSpaceCoordinates.P.center.x == null) {
        // Initialize all values for the first click
        const rectWidth = 1500 / scaleFactor; // Rectangle width
        const rectHeight = 1000 / scaleFactor; // Rectangle height
        const arrowLength = 1750 / scaleFactor; // Arrow length
        const pointOffset = 5000 / scaleFactor; // Perpendicular point offset

        const center = { x: x1, y: y1 }; // Center is the first clicked point
        const angle = 0; // Initial yaw (angle in radians)

        // Calculate initial rectangle corners and related points
        const halfWidth = rectWidth / 2;
        const halfHeight = rectHeight / 2;

        const P1 = {
          x:
            center.x +
            halfWidth * Math.cos(angle) -
            halfHeight * Math.sin(angle),
          y:
            center.y +
            halfWidth * Math.sin(angle) +
            halfHeight * Math.cos(angle),
        };

        const P2 = {
          x:
            center.x -
            halfWidth * Math.cos(angle) -
            halfHeight * Math.sin(angle),
          y:
            center.y -
            halfWidth * Math.sin(angle) +
            halfHeight * Math.cos(angle),
        };

        const P3 = {
          x:
            center.x -
            halfWidth * Math.cos(angle) +
            halfHeight * Math.sin(angle),
          y:
            center.y -
            halfWidth * Math.sin(angle) -
            halfHeight * Math.cos(angle),
        };

        const P4 = {
          x:
            center.x +
            halfWidth * Math.cos(angle) +
            halfHeight * Math.sin(angle),
          y:
            center.y +
            halfWidth * Math.sin(angle) -
            halfHeight * Math.cos(angle),
        };

        const P_prime = {
          x: center.x + arrowLength * Math.cos(angle),
          y: center.y + arrowLength * Math.sin(angle),
        };

        const Px = {
          x: P_prime.x + (pointOffset / 2) * Math.cos(angle + Math.PI / 2),
          y: P_prime.y + (pointOffset / 2) * Math.sin(angle + Math.PI / 2),
        };

        const Py = {
          x: P_prime.x + (pointOffset / 2) * Math.cos(angle - Math.PI / 2),
          y: P_prime.y + (pointOffset / 2) * Math.sin(angle - Math.PI / 2),
        };

        // Set the initial state with all values
        setCartParkingSpaceCoordinates({
          ...cartParkingSpaceCoordinates,
          P: {
            center: { x: center.x, y: center.y },
            x: [P1.x, P2.x, P3.x, P4.x],
            y: [P1.y, P2.y, P3.y, P4.y],
          },
          P_Prime: { x: P_prime.x, y: P_prime.y },
          P1: { x: Px.x, y: Px.y },
          P2: { x: Py.x, y: Py.y },
          yaw: 0, // Initial yaw
        });
      } else {
        // Update yaw and recalculate coordinates for the updated angle
        const dx = x1 - cartParkingSpaceCoordinates.P.center.x;
        const dy = y1 - cartParkingSpaceCoordinates.P.center.y;
        const newAngle = Math.atan2(dy, dx); // New angle in radians

        const rectWidth = 1500 / scaleFactor; // Rectangle width
        const rectHeight = 1000 / scaleFactor; // Rectangle height
        const arrowLength = 1750 / scaleFactor; // Arrow length
        const pointOffset = 5000 / scaleFactor; // Perpendicular point offset

        const center = cartParkingSpaceCoordinates.P.center; // Keep the existing center
        const angle = newAngle; // Updated angle in radians

        // Recalculate rectangle corners and related points
        const halfWidth = rectWidth / 2;
        const halfHeight = rectHeight / 2;

        const P1 = {
          x:
            center.x +
            halfWidth * Math.cos(angle) -
            halfHeight * Math.sin(angle),
          y:
            center.y +
            halfWidth * Math.sin(angle) +
            halfHeight * Math.cos(angle),
        };

        const P2 = {
          x:
            center.x -
            halfWidth * Math.cos(angle) -
            halfHeight * Math.sin(angle),
          y:
            center.y -
            halfWidth * Math.sin(angle) +
            halfHeight * Math.cos(angle),
        };

        const P3 = {
          x:
            center.x -
            halfWidth * Math.cos(angle) +
            halfHeight * Math.sin(angle),
          y:
            center.y -
            halfWidth * Math.sin(angle) -
            halfHeight * Math.cos(angle),
        };

        const P4 = {
          x:
            center.x +
            halfWidth * Math.cos(angle) +
            halfHeight * Math.sin(angle),
          y:
            center.y +
            halfWidth * Math.sin(angle) -
            halfHeight * Math.cos(angle),
        };

        const P_prime = {
          x: center.x + arrowLength * Math.cos(angle),
          y: center.y + arrowLength * Math.sin(angle),
        };

        const Px = {
          x: P_prime.x + (pointOffset / 2) * Math.cos(angle + Math.PI / 2),
          y: P_prime.y + (pointOffset / 2) * Math.sin(angle + Math.PI / 2),
        };

        const Py = {
          x: P_prime.x + (pointOffset / 2) * Math.cos(angle - Math.PI / 2),
          y: P_prime.y + (pointOffset / 2) * Math.sin(angle - Math.PI / 2),
        };

        // Update the state with the new yaw and recalculated points
        setCartParkingSpaceCoordinates((prev) => ({
          ...prev,
          P: {
            center: { x: center.x, y: center.y },
            x: [P1.x, P2.x, P3.x, P4.x],
            y: [P1.y, P2.y, P3.y, P4.y],
          },
          P_Prime: { x: P_prime.x, y: P_prime.y },
          P1: { x: Px.x, y: Px.y },
          P2: { x: Py.x, y: Py.y },
          yaw: radiansToDegrees(newAngle), // Convert yaw to degrees
        }));

        // Clear the canvas and redraw (if applicable)
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }

    async function drawParkingSpace(x, y, angle) {
      // console.log(x,y,angle);

      if (x != null && y != null && angle != null) {
        // Convert angle from degrees to radians for calculations
        
        let angleconversion1 = angle;
        let angleconversion2 = angle;
        angleconversion1 =
          xFlipped == -1
            ? angle < 0
              ? -180 - angle
              : 180 - angle
            : angle;

        angleconversion2 =
          yFlipped == -1 ? -angleconversion1 : angleconversion1;

        const degreesToRadians = (degrees) => degrees * (Math.PI / 180);
        
        const arrowAngle = (angleconversion2);
        // console.log(arrowAngle);
        

        const radius = 400 / scaleFactor;
        const arrowLength = 1500 / scaleFactor;

        // Calculate the arrow's tip coordinates
        const arrowX = x + arrowLength * Math.cos((arrowAngle * Math.PI) / 180);
        const arrowY = y + arrowLength * Math.sin((arrowAngle * Math.PI) / 180);

        // Draw the parking space circle
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, 2 * Math.PI);
        ctx.strokeStyle = "blue"; // Set the color for the circle
        ctx.lineWidth = 4; // Set the line width for the circle
        ctx.stroke();
        ctx.closePath();

        // Draw the arrow line
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(arrowX, arrowY);
        ctx.strokeStyle = "blue";
        ctx.lineWidth = 2;
        ctx.stroke();
        ctx.closePath();

        // Helper function to draw the arrowhead
        const drawArrow = (p1, p2) => {
          const arrowSize = 10;
          const dx = p2.x - p1.x;
          const dy = p2.y - p1.y;
          const angle = Math.atan2(dy, dx);

          const arrowPoint1 = {
            x: p2.x - arrowSize * Math.cos(angle - Math.PI / 6),
            y: p2.y - arrowSize * Math.sin(angle - Math.PI / 6),
          };

          const arrowPoint2 = {
            x: p2.x - arrowSize * Math.cos(angle + Math.PI / 6),
            y: p2.y - arrowSize * Math.sin(angle + Math.PI / 6),
          };

          ctx.fillStyle = "blue"; // Set arrow color to blue
          ctx.beginPath();
          ctx.moveTo(p2.x, p2.y); // Arrow tip
          ctx.lineTo(arrowPoint1.x, arrowPoint1.y); // Left side of arrowhead
          ctx.lineTo(arrowPoint2.x, arrowPoint2.y); // Right side of arrowhead
          ctx.closePath();
          ctx.fill();
        };

        // Draw the arrowhead
        drawArrow({ x: x, y: y }, { x: arrowX, y: arrowY });
      }
    }

    function drawCartParkingSpace(cartCoordinates, state) {
      if (
        cartCoordinates.Zone &&
        cartCoordinates.Zone.center &&
        cartCoordinates.Zone.center.x != null &&
        cartCoordinates.Zone.center.y != null
      ) {
        const rectWidth = 1500 / scaleFactor;
        const rectHeight = 1000 / scaleFactor;
        const arrowLength = 1750 / scaleFactor;
        const pointOffset = 5000 / scaleFactor;
        let angleconversion1 = cartCoordinates.yaw;
        let angleconversion2 = cartCoordinates.yaw;
        angleconversion1 =
          xFlipped == -1
            ? cartCoordinates.yaw < 0
              ? -180 - cartCoordinates.yaw
              : 180 - cartCoordinates.yaw
            : cartCoordinates.yaw;

        angleconversion2 =
          yFlipped == -1 ? -angleconversion1 : angleconversion1;

        const degreesToRadians = (degrees) => degrees * (Math.PI / 180);
        const applyTransformation = (x, y) => {
          return {
            x:
              (xFlipped * x * 10) / scaleFactor -
              img.naturalWidth / 2 +
              originX,
            y:
              (yFlipped * y * 10) / scaleFactor -
              img.naturalHeight / 2 +
              originY,
          };
        };

        const drawAllElements = (center, width, height, angle) => {
          ctx.save();
          ctx.translate(center.x, center.y);
          ctx.rotate(angle);

          ctx.beginPath();
          ctx.rect(-width / 2, -height / 2, width, height);
          ctx.fillStyle =
            state === "full" ? "rgba(255, 0, 0, 0.3)" : "rgba(0, 255, 0, 0.3)";
          ctx.fill();
          ctx.strokeStyle = "blue";
          ctx.lineWidth = 2;
          ctx.stroke();
          ctx.closePath();
          const arrowX = arrowLength * Math.cos(0);
          const arrowY = arrowLength * Math.sin(0);
          ctx.beginPath();
          ctx.moveTo(0, 0);
          ctx.lineTo(arrowX, arrowY);
          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          ctx.stroke();
          ctx.closePath();

          ctx.restore();
          ctx.save();
          ctx.translate(center.x, center.y);
          ctx.font = "bold 25px Arial";
          ctx.fillStyle = "white";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(state === "full" ? "F" : "E", 0, 0);
          ctx.restore();
          const docking_point = applyTransformation(
            cartCoordinates.Docking_Point.x,
            cartCoordinates.Docking_Point.y
          );

          const undocking_point_1 = applyTransformation(
            cartCoordinates.Undocking_Point_1.x,
            cartCoordinates.Undocking_Point_1.y
          );

          const undocking_point_2 = applyTransformation(
            cartCoordinates.Undocking_Point_2.x,
            cartCoordinates.Undocking_Point_2.y
          );

          ctx.beginPath();
          ctx.arc(docking_point.x, docking_point.y, 5, 0, 2 * Math.PI);
          ctx.fillStyle = "green";
          ctx.fill();
          ctx.closePath();

          ctx.beginPath();
          ctx.arc(undocking_point_1.x, undocking_point_1.y, 5, 0, 2 * Math.PI);
          ctx.arc(undocking_point_2.x, undocking_point_2.y, 5, 0, 2 * Math.PI);
          ctx.fillStyle = "purple";
          ctx.fill();
          ctx.closePath();
        };

        const center = applyTransformation(
          cartCoordinates.Zone.center.x,
          cartCoordinates.Zone.center.y
        );

        const angle = degreesToRadians(angleconversion2);
        drawAllElements(center, rectWidth, rectHeight, angle);
      } else {
        console.error("Invalid cart parking space coordinates.");
      }
    }

    async function drawBotPath() {
      ctx.strokeStyle = "blue";

      // Use copies of botTraceArray to avoid mutating the original data
      let xList = [...botTraceArray.x];
      let yList = [...botTraceArray.y];
      let xList1 = [...botTraceArray.x];
      let yList1 = [...botTraceArray.y];
      if (AIpath) {
        // ai_smooth();

        let points1 = removeClosePoints(xList, yList);
        let points = addIntermediatePoints(points1, 10);
        let lastPointsX = pathRecord.x[pathRecord.x.length - 1];
        let lastPointsY = pathRecord.y[pathRecord.y.length - 1];

        xList = [];
        yList = [];
        var window_size = AIPathSensitivity;

        let smoothedPoints = smoothPath(points, window_size);
        xList.push(pathRecord.x[0]);
        yList.push(pathRecord.y[0]);
        for (let i = 0; i < smoothedPoints.length; i++) {
          xList.push(smoothedPoints[i].x);
          yList.push(smoothedPoints[i].y);
        }
        var straightig_threshold = 10;
        var straighten_points = alignPathToAxes(
          xList,
          yList,
          straightig_threshold
        );
        xList = straighten_points.pose.x;
        yList = straighten_points.pose.y;

        points = removeClosePoints(xList, yList);

        // Prepare the final smoothed path
        xList = [];
        yList = [];
        var window_size = AIPathSensitivity;
        smoothedPoints = smoothPath(points, window_size);
        for (let i = 0; i < smoothedPoints.length; i++) {
          xList.push(smoothedPoints[i].x);
          yList.push(smoothedPoints[i].y);
        }

        var straightig_threshold = 10;
        var straighten_points = alignPathToAxes(
          xList,
          yList,
          straightig_threshold
        );
        xList = straighten_points.pose.x;
        yList = straighten_points.pose.y;

        points = removeClosePoints(xList, yList);

        xList = [];
        yList = [];

        smoothedPoints = await smoothPath(points, window_size);
        for (let i = 0; i < smoothedPoints.length; i++) {
          xList.push(smoothedPoints[i].x);
          yList.push(smoothedPoints[i].y);
        }
        xList.push(lastPointsX);
        yList.push(lastPointsY);

        setAIpathArray({
          x: xList,
          y: yList,
        });

        ctx.beginPath();
        ctx.moveTo(xList[0], yList[0]);
        ctx.lineWidth = 4;

        // Draw the path using the smoothed points
        for (let i = 0; i < xList.length; i++) {
          ctx.lineTo(xList[i], yList[i], xList[i + 1], yList[i + 1]);
        }
        ctx.strokeStyle = "lime";
        ctx.stroke();
        ctx.closePath();
      }

      // Draw the original bot path (blue path)
      ctx.beginPath();
      ctx.moveTo(
        (xFlipped * xList1[0]) / scaleFactor - img.naturalWidth / 2 + originX,
        (yFlipped * yList1[0]) / scaleFactor - img.naturalHeight / 2 + originY
      );
      ctx.lineWidth = 3;
      for (let i = 1; i < xList1.length; i++) {
        ctx.lineTo(
          (xFlipped * xList1[i]) / scaleFactor - img.naturalWidth / 2 + originX,
          (yFlipped * yList1[i]) / scaleFactor - img.naturalHeight / 2 + originY
        );
      }
      ctx.stroke();
      ctx.closePath();
    }

    function drawBotJitterPath() {
      ctx.strokeStyle = "red";
      const xList = jitterbotCoordinates.x;
      const yList = jitterbotCoordinates.y;
      ctx.beginPath();
      ctx.lineWidth = 3;
      // ctx.moveTo(xList[0], yList[0]);
      for (let i = 0; i < xList.length; i++) {
        ctx.lineTo(
          (xFlipped * xList[i]) / scaleFactor - img.naturalWidth / 2 + originX,
          (yFlipped * yList[i]) / scaleFactor - img.naturalHeight / 2 + originY
        );
      }
      ctx.stroke();
      ctx.closePath();
    }
    const getEventLocation = (e) => {
      if (e.touches && e.touches.length === 1) {
        return { x: e.touches[0].clientX, y: e.touches[0].clientY };
      } else if (e.touches && e.touches.length === 2) {
        return {
          x: (e.touches[0].clientX + e.touches[1].clientX) / 2,
          y: (e.touches[0].clientY + e.touches[1].clientY) / 2,
        };
      } else {
        return { x: e.clientX, y: e.clientY };
      }
    };

    const getMousePosition = (canvasElem, event) => {
      let rect = canvasElem.getBoundingClientRect();
      let record_x = event.clientX - rect.left;
      let record_y = event.clientY - rect.top;
      record_x = (record_x - window.innerWidth / 2) / cameraZoom;
      record_y = (record_y - window.innerHeight / 2) / cameraZoom;
      record_x = record_x + window.innerWidth / 2 - cameraOffset.x;
      record_y = record_y + window.innerHeight / 2 - cameraOffset.y;
      if (stationRecord) {
        setStationMarkedCoordinate({ x: record_x, y: record_y });
      }
      if (CreateZone) {
        handleZoneClick(record_x, record_y);
      }

      if (startParkingSpaceRecord) {
        recordParkingSpace(record_x, record_y);
      }
      if (startCartParkingSpaceRecord) {
        // recordParkingSpace(record_x, record_y);
        recordCartParkingSpace(record_x, record_y);
      }
      if (record_x && record_y) {
        if (startRecord) {
          setPathRecord((prev) => ({
            x: [...prev.x, record_x],
            y: [...prev.y, record_y],
          }));
        }
      }
    };

    const onPointerDown = (e) => {
      isDragging = true;
      dragStart.x = getEventLocation(e).x / cameraZoom - cameraOffset.x;
      dragStart.y = getEventLocation(e).y / cameraZoom - cameraOffset.y;

      if (ToRecord) {
        setStartRecord(true);
        getMousePosition(canvas, e);
      } else if (stationRecord) {
        getMousePosition(canvas, e);
        setStationRecord(!stationRecord);
      }
    };

    const onPointerUp = (e) => {
      if (CreateZone) {
        getMousePosition(canvas, e);
      }
      setStartRecord(false);
      getMousePosition(canvas, e);
      isDragging = false;
      initialPinchDistance = null;
      lastZoom = cameraZoom;
    };

    const onPointerMove = (e) => {
      if (startRecord) {
        getMousePosition(canvas, e);
      } else if (isDragging && !ToRecord) {
        cameraOffset.x +=
          (getEventLocation(e).x / cameraZoom - dragStart.x - cameraOffset.x) *
          DRAG_SENSITIVITY;
        cameraOffset.y +=
          (getEventLocation(e).y / cameraZoom - dragStart.y - cameraOffset.y) *
          DRAG_SENSITIVITY;
      }
    };

    const handleTouch = (e, singleTouchHandler) => {
      if (e.touches.length === 1) {
        singleTouchHandler(e);
      } else if (e.type === "touchmove" && e.touches.length === 2) {
        isDragging = false;
        handlePinch(e);
      }
    };

    const handlePinch = (e) => {
      e.preventDefault();

      const touch1 = e.touches[0];
      const touch2 = e.touches[1];

      const currentDistance = Math.hypot(
        touch1.clientX - touch2.clientX,
        touch1.clientY - touch2.clientY
      );

      if (initialPinchDistance === null) {
        initialPinchDistance = currentDistance;
        lastZoom = cameraZoom;
      } else {
        setCameraZoom(
          Math.max(
            MIN_ZOOM,
            Math.min(
              MAX_ZOOM,
              lastZoom * (currentDistance / initialPinchDistance)
            )
          )
        );
      }
    };

    const adjustZoom = (zoomAmount, zoomFactor) => {
      if (!isDragging) {
        if (zoomAmount) {
          setCameraZoom((prevZoom) =>
            Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, prevZoom - zoomAmount))
          );
        } else if (zoomFactor) {
          setCameraZoom((prevZoom) =>
            Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, zoomFactor * lastZoom))
          );
        }
      }
    };
    const findDistance = (
      oldCoordinateX,
      oldCoordinateY,
      newCoordinateX,
      newCoordinateY
    ) => {
      return Math.sqrt(
        (newCoordinateX - oldCoordinateX) * (newCoordinateX - oldCoordinateX) +
          (newCoordinateY - oldCoordinateY) * (newCoordinateY - oldCoordinateY)
      );
    };

    const traceBots = ({ botCoordinateX, botCoordinateY, minDistance }) => {
      setBotTraceArray((prevState) => {
        // If there are no previous coordinates, add the first one.
        if (prevState.x.length === 0 || prevState.y.length === 0) {
          return {
            x: [botCoordinateX],
            y: [botCoordinateY],
          };
        }

        const lastX = prevState.x[prevState.x.length - 1];
        const lastY = prevState.y[prevState.y.length - 1];

        // Calculate the distance from the last point
        const distance = findDistance(
          lastX,
          lastY,
          botCoordinateX,
          botCoordinateY
        );

        // Only add the new coordinates if they exceed the minimum distance
        if (distance >= minDistance) {
          return {
            x: [...prevState.x, botCoordinateX],
            y: [...prevState.y, botCoordinateY],
          };
        }

        // Return the previous state if the distance is too small
        return prevState;
      });

      // Update jitterbotCoordinates as before, but also with the distance threshold
      setjitterbotCoordinates((prevState) => {
        const newJitterX = [...prevState.x, botCoordinateX];
        const newJitterY = [...prevState.y, botCoordinateY];

        // Keep the last 100 jitter coordinates
        if (newJitterX.length > 100) {
          newJitterX.shift();
          newJitterY.shift();
        }

        return { x: newJitterX, y: newJitterY };
      });
    };

    if (tagName) {
      traceBots({
        botCoordinateX: selectedTag.botCoordinateX,
        botCoordinateY: selectedTag.botCoordinateY,
        minDistance: 5,
      });
    }

    canvas.addEventListener("mousedown", onPointerDown);
    canvas.addEventListener("touchstart", (e) => handleTouch(e, onPointerDown));
    canvas.addEventListener("mouseup", onPointerUp);
    canvas.addEventListener("touchend", (e) => handleTouch(e, onPointerUp));
    canvas.addEventListener("mousemove", onPointerMove);
    canvas.addEventListener("touchmove", (e) => handleTouch(e, onPointerMove));
    canvas.addEventListener("wheel", (e) =>
      adjustZoom(e.deltaY * SCROLL_SENSITIVITY)
    );

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();
    draw();

    return () => {
      canvas.removeEventListener("mousedown", onPointerDown);
      canvas.removeEventListener("touchstart", (e) =>
        handleTouch(e, onPointerDown)
      );
      canvas.removeEventListener("mouseup", onPointerUp);
      canvas.removeEventListener("touchend", (e) =>
        handleTouch(e, onPointerUp)
      );
      canvas.removeEventListener("mousemove", onPointerMove);
      canvas.removeEventListener("touchmove", (e) =>
        handleTouch(e, onPointerMove)
      );
      canvas.removeEventListener("wheel", (e) =>
        adjustZoom(e.deltaY * SCROLL_SENSITIVITY)
      );
      window.removeEventListener("resize", resizeCanvas);
    };
  }, [
    tags,
    botMapping,
    materialMapping,
    PeopleMapping,
    cameraZoom,
    cameraOffset,
    stationRecord,
    botTraceArray,
    jitterbotCoordinates,
    stationMarkedCoordinate,
    startRecord,
    tracePath,
    tagName,
    showBots,
    showZone,
    showParking,
    setShowParking,
    selectedParking,
    setSelectedParking,
    showMaterials,
    showPeople,
    ToRecord,
    showStation,
    showCartParking,
    selectedCartParking,
    showpath,
    hoveredPath,
    setHoveredPath,
    AIpath,
    selectedPath,
    selectedZone,
    setSelectedZone,
    selectedStation,
    selectedBot,
    selectedPeople,
    selectedTag,
    AIPathSensitivity,
    joystickPosition,
    CreateZone,
    ZonePointArray,
    opacity,
    startParkingSpaceRecord,
    parkingSpaceCoordinates,
    startCartParkingSpaceRecord,
    setStartCartParkingSpaceRecord,
    cartParkingSpaceCoordinates,
    setCartParkingSpaceCoordinates,
  ]);

  return (
    <canvas className="w-[100%] h-[100%]" ref={canvasRef} id="canvas"></canvas>
  );
};

export default Canvas;
