export default function convertToReal(x,y){    
    var xFlipped = 1;
    var yFlipped = -1;
    var scaleFactor = 24.1304520095363,
      originX = 23462.84145899498 / scaleFactor,
      originY = yFlipped * (-55755.01018319527 / scaleFactor);
      const img = new Image();
      img.src = "Drobot.jpg";

      const convertedX =
          ((x / xFlipped +
            (xFlipped * img.naturalWidth) / 2 -
            xFlipped * originX) *
            scaleFactor) /
          10;
        const convertedY =
          ((y / yFlipped +
            (yFlipped * img.naturalHeight) / 2 -
            yFlipped * originY) *
            scaleFactor) /
          10;

          return ({convertedX,convertedY});

}