import { Client as PahoClient } from 'paho-mqtt';
import TagsList from './components/helper_functions/TagList.js';

class MQTTClient {
  constructor(setTags) {
    this.setTags = setTags;
    this.tagList = new TagsList();
    this.client = null;
    this.connect();
  }

  connect() {
    // var clientID = JSON.parse(window.sessionStorage.getItem("data")).uid
    var clientID = "ONxFcV4EqfRtUJA1Qzl9HioNkU13" + Math.floor(Math.random() * 1100).toString(8);
    let host = "mqtt.cloud.pozyxlabs.com";
    let port = "443";
    // console.log(clientID);
    

    this.client = new PahoClient(host, Number(port), clientID);

    this.client.onConnectionLost = this.onConnectionLost.bind(this);
    this.client.onMessageArrived = this.onMessageArrived.bind(this);
    
    var details = {
      onSuccess: this.onConnect.bind(this),
      onFailure: this.onConnectFailure.bind(this),
      userName: "611d2d24608bde02f7eb29b4",
      password: "76f2a263-47ff-444a-81f3-1453a0985a93",
      useSSL: true,
      cleanSession: false,
    };

    this.client.connect(details);
  }

  onConnect() {
    console.log("Connected to MQTT broker");
    this.client.subscribe("611d2d24608bde02f7eb29b4", {
      onSuccess: () => {
        console.log("Subscribed to the topic successfully");
      },
      onFailure: (err) => {
        console.log("Failed to subscribe to the topic:", err);
      },
    });
  }

  onConnectFailure(err) {
    console.log("Failed to connect to MQTT broker:", err);
  }

  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log("Connection lost:", responseObject.errorMessage);
    }
  }

  onMessageArrived(message) {
    try {
      const data = JSON.parse(message.payloadString);
      // console.log(data);
      data.forEach((tag) => {
        if (!this.tagList.TagsList[tag.tagId]) {
          // Add the tag to the tag list if it doesn't already exist
          this.tagList.addTag(tag.tagId);
        }
  
        // Update tag info if `data` and `coordinates` are available
        if (tag.data && tag.data.coordinates) {
          this.tagList.setTagInfo(tag.tagId, "coordinates", [
            tag.data.coordinates.x,
            tag.data.coordinates.y,
          ]);
        }
      });
  
      this.setTags(this.tagList.TagsList);
    } catch (error) {
      console.error("Error processing message:", error, "Message:", message.payloadString);
    }
  }
}

export default MQTTClient;
