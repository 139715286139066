import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+1'); // Default country code
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    // Combine country code and contact number
    const contact = `${countryCode}${contactNumber}`;

    try {
      const response = await axios.post('https://drobot-admin-v2-a2def93839bb.herokuapp.com/register', {
        username,
        email,
        fullName,
        contact,
        password,
      });

      console.log(response);
      
      if (response.data.message === 'User registered successfully.') {
        navigate('/login');
      } else {
        console.error('Registration failed:', response.data);
      }
    } catch (error) {
      console.error('Registration error:', error);
    }
  };

  const countryCodes = [
    { code: '+93', name: 'Afghanistan' },
    { code: '+355', name: 'Albania' },
    { code: '+213', name: 'Algeria' },
    { code: '+61', name: 'Australia' },
    { code: '+43', name: 'Austria' },
    { code: '+880', name: 'Bangladesh' },
    { code: '+32', name: 'Belgium' },
    { code: '+55', name: 'Brazil' },
    { code: '+1', name: 'Canada' },
    { code: '+86', name: 'China' },
    { code: '+20', name: 'Egypt' },
    { code: '+33', name: 'France' },
    { code: '+49', name: 'Germany' },
    { code: '+91', name: 'India' },
    { code: '+62', name: 'Indonesia' },
    { code: '+98', name: 'Iran' },
    { code: '+964', name: 'Iraq' },
    { code: '+39', name: 'Italy' },
    { code: '+81', name: 'Japan' },
    { code: '+254', name: 'Kenya' },
    { code: '+82', name: 'South Korea' },
    { code: '+961', name: 'Lebanon' },
    { code: '+60', name: 'Malaysia' },
    { code: '+52', name: 'Mexico' },
    { code: '+212', name: 'Morocco' },
    { code: '+31', name: 'Netherlands' },
    { code: '+234', name: 'Nigeria' },
    { code: '+47', name: 'Norway' },
    { code: '+92', name: 'Pakistan' },
    { code: '+63', name: 'Philippines' },
    { code: '+351', name: 'Portugal' },
    { code: '+7', name: 'Russia' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+27', name: 'South Africa' },
    { code: '+34', name: 'Spain' },
    { code: '+94', name: 'Sri Lanka' },
    { code: '+46', name: 'Sweden' },
    { code: '+41', name: 'Switzerland' },
    { code: '+66', name: 'Thailand' },
    { code: '+90', name: 'Turkey' },
    { code: '+44', name: 'United Kingdom' },
    { code: '+1', name: 'United States' },
    { code: '+58', name: 'Venezuela' },
    { code: '+84', name: 'Vietnam' },
    { code: '+260', name: 'Zambia' },
    { code: '+263', name: 'Zimbabwe' },
];


  return (
    <div className="w-full h-screen bg-gradient-to-b from-[#015d81] to-[#02394a] flex items-center justify-center">
      <div className="w-full max-w-md bg-white px-10 py-8 rounded-lg shadow-2xl shadow-gray-800 transition duration-300 transform hover:scale-105">
        <div className="flex justify-center mb-6">
          <img src="logo.png" alt="Drobot Logo" className="h-20 w-20" />
        </div>
        <p className="text-center text-md mb-6 text-gray-700 font-semibold">Register yourself to see more</p>
        <form onSubmit={handleRegister} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
            <input
              id="username"
              name="username"
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="fullname" className="block text-sm font-medium text-gray-700">Full Name</label>
            <input
              id="fullname"
              name="fullname"
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="contact" className="block text-sm font-medium text-gray-700">Contact</label>
            <div className="flex space-x-2">
              <select
                id="countryCode"
                name="countryCode"
                className="block w-32 border border-gray-300 rounded-md px-2 py-2"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
              >
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.code} ({country.name})
                  </option>
                ))}
              </select>
              <input
                id="contactNumber"
                name="contactNumber"
                type="tel"
                className="flex-1 block w-full border border-gray-300 rounded-md px-3 py-2"
                placeholder="Contact Number"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white rounded-full py-2 px-4 font-semibold"
          >
            Continue
          </button>
          <p className="text-xs text-center text-gray-700 mt-2">
            By continuing, you agree to Drobot's Terms of Service and acknowledge you've read our Privacy Policy. Notice at collection.
          </p>
        </form>
        <a href="/" className="block text-center text-sm font-semibold mt-4 text-blue-600">
          Already a member? Log in
        </a>
      </div>
    </div>
  );
};

export default Register;
