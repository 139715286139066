import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
function Companies() {
  const [companies, setCompanies] = useState([]);
  const navigate=useNavigate();

  useEffect(() => {
  const fetchCompanies = async () => {
    
    try {
      const response = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/companies",{
        userUid:JSON.parse(window.sessionStorage.getItem("data")).uid
      }, {
        withCredentials: true, // if you are dealing with cookies
      });
      
      // No need to call response.json(), use response.data directly
      const data = response.data;
      
      setCompanies(data.companies); // Assuming data.companies is the correct structure
      console.log(companies);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  fetchCompanies();
}, []);

  const setCompanyData=async(companyName)=>{
    const data=await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",{company:companyName});
    console.log(data.data);
    window.sessionStorage.setItem("stationData", JSON.stringify(data.data.stationdata)); 
    window.sessionStorage.setItem("companyData", JSON.stringify(data.data.companydata)); 
    window.sessionStorage.setItem("companyName", companyName);
    await window.sessionStorage.setItem("botParkingSpaceData", JSON.stringify(data.data["stationdata"].map((station) => {
      if (station.bot_parking != null) {
        return{... station.bot_parking,
          station:station.stationName
        };
      }
      return null;
    }).filter(parking => parking !== null)));
    setpathData(companyName);
    setzoneData(companyName);
  }
  const setpathData=async(companyName)=>{
    const data=await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getpathdata",{company:companyName});
    window.sessionStorage.setItem("pathData", JSON.stringify(data.data));
    navigate("/home")
  }
  const setzoneData=async(companyName)=>{
    const data=await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getzonedata",{company:companyName});
    window.sessionStorage.setItem("zoneData", JSON.stringify(data.data));
    navigate("/home")
  }

  return (
    <div className="w-full h-screen bg-[#015d81] flex justify-center items-center">
  <div className="bg-[#015d81] p-8 rounded-lg flex flex-col items-center gap-6">
    {/* Logo Section */}
    <img src="svgs/drobot_logo.svg" alt="Drobot" className="mb-6 w-[150px]" />

    {/* Companies Container */}
    <div className="bg-white w-[90%] sm:w-[70%] lg:w-[55vw] h-[60vh] overflow-y-auto shadow-lg rounded-lg flex flex-col items-center gap-4 p-6">
      <p className="text-black-600 text-xl  mb-4 font-semibold">
        Select a company to view details:
      </p>
      
      {/* Company Buttons */}
      <div className="w-full flex flex-wrap justify-center items-center gap-6">
        {companies.map((company) => (
          <div
            key={company.id}
            className="h-[5rem] w-[12rem] bg-[#015d81] border-black border rounded-lg flex justify-center items-center text-xl font-semibold text-white shadow-lg hover:bg-[#0178a3] hover:scale-105 transition-all duration-300 cursor-pointer"
            onClick={() => setCompanyData(company["Company Name"])}
          >
            {company["Company Name"]}
          </div>
        ))}
      </div>
    </div>
  </div>
</div>


  );
}

export default Companies;
