import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/login", {
        email,
        password,
      },{
        withCredentials: true,
      });
      console.log(response);
      if (response.data.message === "Logged in successfully.") {
        window.localStorage.setItem("auth", "true");
        console.log(response.data.data);
        
        window.sessionStorage.setItem("data",JSON.stringify(response.data.data))
        navigate("/companies");
      } else {
        console.error("Login failed:", response.data);
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <div className="w-full h-screen bg-gradient-to-b from-[#015d81] to-[#02394a] flex items-center justify-center">
  <div className="w-full max-w-md bg-white px-10 py-8 rounded-lg shadow-2xl shadow-gray-800 transition duration-300 transform hover:scale-105">
    <div className="flex justify-center mb-6">
      <img src="logo.png" alt="Drobot Logo" className="h-20 w-20" />
    </div>
    <p className="text-center text-md mb-4 text-gray-700 font-semibold">Use Your Account</p>
    <form onSubmit={handleLogin} className="space-y-6">
      <div>
        <label htmlFor="email" className="block text-sm font-semibold text-gray-700 ">Email</label>
        <input
          id="email"
          name="email"
          type="email"
          className="block w-full border border-gray-300 rounded-lg px-4 py-2 focus:border-blue-400 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password" className="block text-sm font-semibold text-gray-700 ">Password</label>
        <input
          id="password"
          name="password"
          type="password"
          className="block w-full border border-gray-300 rounded-lg px-4 py-2 focus:border-blue-400 focus:ring focus:ring-blue-200 focus:ring-opacity-50 mb-2"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button
        type="submit"
        className="w-full bg-[#015d81] hover:scale-105 hover:bg-[#133846] text-white rounded-lg py-2 px-4 font-semibold shadow-lg hover:shadow-xl transition duration-300 ease-in-out"
      >
        Continue
      </button>
      <p className="text-xs text-center text-gray-600 mt-3">
        By continuing, you agree to Drobot's Terms of Service and acknowledge you've read our Privacy Policy. Notice at collection.
      </p>
    </form>
    <a href="/register" className="block text-center text-sm font-semibold mt-6 text-blue-600 hover:underline">
      New member? Register
    </a>
  </div>
</div>

  );
};

export default Login;
